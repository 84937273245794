import { Appointment, AppointmentStatusType, parseUTC } from '@aposphaere/core-kit'
import { TimeSlotKind } from '@aposphaere/ui-components'
import { isPast, isToday } from 'date-fns'
import { StatusWithCancelledProp } from '../../hooks/graphql'

export const isAppointmentAVisit = (appointment: Appointment): boolean => !!appointment?.appointmentType?.label?.toLowerCase()?.includes('besuch')

export const getAppointmentKind = (appointment: Appointment, statuses: StatusWithCancelledProp[]): TimeSlotKind => {
  if (appointment?.is_deleted) {
    return TimeSlotKind.deleted
  }
  const date = appointment?.date ? parseUTC(appointment.date) : null

  const status = statuses.find((_status) => String(appointment.status_id) === String(_status.id))

  if (!status || !date) {
    return TimeSlotKind.booked
  }

  if (status.isCancelled) {
    return TimeSlotKind.deleted
  }

  if (isToday(date)) {
    return Number(status.id) === AppointmentStatusType.Completed ? TimeSlotKind.finished : TimeSlotKind.booked
  }
  if (isPast(date)) {
    return Number(status.id) === AppointmentStatusType.Completed ? TimeSlotKind.finished : TimeSlotKind.missed
  }

  return TimeSlotKind.booked
}

export const getOfftimeKind = (isUserCallcenter: boolean, type?: string): TimeSlotKind => {
  if (!type) {
    return TimeSlotKind.available
  }

  if (isUserCallcenter) {
    return TimeSlotKind.privat
  }

  const rawKind = type.split(' ').join('').toLowerCase()

  if (rawKind.includes('feiertag')) {
    return TimeSlotKind.festive
  }
  if (rawKind.includes('homeoffice')) {
    return TimeSlotKind.homeOffice
  }
  if (rawKind.includes('krank')) {
    return TimeSlotKind.disease
  }
  if (rawKind.includes('privat')) {
    return TimeSlotKind.privat
  }
  if (rawKind.includes('tagung')) {
    return TimeSlotKind.conference
  }
  if (rawKind.includes('urlaub')) {
    return TimeSlotKind.vacation
  }
  if (rawKind.includes('unbesetzt')) {
    return TimeSlotKind.unoccupied
  }
  return TimeSlotKind.available
}

export const determineDayItemHoverText = (kind: TimeSlotKind) => {
  switch (kind) {
    case TimeSlotKind.available:
      return 'verfügbar'
    case TimeSlotKind.blocked:
      return 'nicht verfügbar'
    case TimeSlotKind.booked:
      return 'geplant'
    case TimeSlotKind.conference:
      return 'Tagung'
    case TimeSlotKind.disabled:
      return 'nicht verfügbar'
    case TimeSlotKind.disease:
      return 'krank'
    case TimeSlotKind.festive:
      return 'Feiertag'
    case TimeSlotKind.finished:
      return 'abgeschlossen'
    case TimeSlotKind.homeOffice:
      return 'Home Office'
    case TimeSlotKind.privat:
      return 'privat'
    case TimeSlotKind.today:
      return 'heute'
    case TimeSlotKind.vacation:
      return 'Urlaub'
    default:
      return ''
  }
}
