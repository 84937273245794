import {
  allAppointmentTypes,
  Appointment,
  AppointmentType,
  getAppointments,
  getNextPossibleDateQuery,
  useAuth,
  useGqlClient,
} from '@aposphaere/core-kit'
import { minutesToMilliseconds } from 'date-fns'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useCrmContext } from '../../contexts/crmContext'
import { QueryOptions } from './utils'

/*
 * QUERIES
 */

type AppointmentQueryProps = {
  options?: QueryOptions<Appointment[]>
  clusterId?: string
  date?: { from: string; to: string }
  enabled?: boolean
}
export const useAppointmentsQuery = (props?: AppointmentQueryProps) => {
  const gqlClient = useGqlClient()
  const { activeQuarter, activeCluster } = useCrmContext()
  const clusterId = props?.clusterId || activeCluster.id

  const variables = { date: props?.date, clusterId, quarterId: activeQuarter.id }

  return useQuery(
    ['appointments', `quarter-${activeQuarter.id}`, `cluster-${clusterId}`, `dateRange-${JSON.stringify(props?.date)}`],
    async () => {
      const data = await gqlClient.request<{ appointments: Appointment[] }>(getAppointments, variables)
      return data?.appointments
    },
    { staleTime: Infinity, enabled: props?.enabled ?? true, ...props?.options },
  )
}

export const useAppointmentQuery = (id: number | undefined) => {
  const appointmentsQuery = useAppointmentsQuery()
  const appointmentsQueryData = useMemo(() => appointmentsQuery?.data ?? [], [appointmentsQuery])

  const appointment = useMemo(() => appointmentsQueryData.find((_appointment) => _appointment.id === id) ?? null, [appointmentsQueryData, id])
  return { isLoading: appointmentsQuery.isLoading, data: appointment, refetch: appointmentsQuery.refetch }
}

export const useAppointmentTypesQuery = (options?: QueryOptions<{ appointmentTypes: AppointmentType[] }>) => {
  const gqlClient = useGqlClient()
  const auth = useAuth()
  return useQuery('appointment_types', () => gqlClient.request<{ appointmentTypes: AppointmentType[] }>(allAppointmentTypes), {
    enabled: !!auth.user,
    staleTime: Infinity,
    ...options,
  })
}

export const useNextPossibleDateQuery = (pharmacyId: string | number | undefined, options?: QueryOptions<string>) => {
  const gqlClient = useGqlClient()
  const auth = useAuth()
  const { activeQuarter } = useCrmContext()

  return useQuery<string>(
    ['next-possible-date', `quarter-${activeQuarter.id}`, `pharmacy-${pharmacyId || ''}`],
    async () => {
      const response = await gqlClient.request<{
        getNextPossibleDate: string
      }>(getNextPossibleDateQuery, {
        quarter_id: activeQuarter.id,
        pharmacy_id: pharmacyId,
      })
      return response?.getNextPossibleDate
    },
    {
      enabled: !!auth.user && !!pharmacyId,
      staleTime: minutesToMilliseconds(1),
      ...options,
    },
  )
}

/*
 * MUTATIONS
 */
