import React, { useCallback } from 'react'
import { useDashboardContext } from '../../../contexts/dashboardContext'
import { AgentSelectorItemType } from './AgentSelector'
import { useCrmContext } from '../../../contexts/crmContext'

type AgentSelectorItemProps = {
  itemData: AgentSelectorItemType
}

export const AgentSelectorItem = ({ itemData: { id, clusterName, agentName } }: AgentSelectorItemProps) => {
  const {
    GENERAL: { clusterSelected, setClusterSelected },
  } = useDashboardContext()
  const { setActiveClusterId } = useCrmContext()
  const isSelected = clusterSelected === id

  const wrapperStyle = `flex flex-col justify-center items-center border-b w-full h-14 p-2 text-center cursor-pointer ${
    isSelected ? 'bg-blue-600 text-white' : ''
  }`
  const clusterTextStyle = `uppercase ${isSelected ? '' : 'text-blue-700'} text-xxs truncate w-full`

  const handleOnAgentSelect = useCallback(() => {
    setClusterSelected(id)
    setActiveClusterId(id)
  }, [setClusterSelected, id])

  return (
    <div className={wrapperStyle} onClick={handleOnAgentSelect}>
      <div className="font-bold	text-base truncate w-full">{agentName}</div>
      <div className={clusterTextStyle}>{clusterName}</div>
    </div>
  )
}
