import { Button, ButtonKind, Modal, ModalKind } from '@aposphaere/ui-components'
import React from 'react'
import { useModal } from '../../contexts/modalContext'
import { format } from 'date-fns'
import { ModalKind as AppModalKind } from '../../contexts/modalContext'
import { useProjectsQuery } from '../../hooks/graphql'

interface AppointmentCancelModal {
  pharmacyId?: number | undefined
  date?: Date
  order_items?: {
    project_id: string
    amount: number
  }[]
}
const ExportModal: React.FC<AppointmentCancelModal> = (props) => {
  const { closeModal, openModal } = useModal()
  const { data: projects } = useProjectsQuery()

  const projectNames = props.order_items?.map((orderItem) => {
    const project = projects?.find((project) => project.id.toString() === orderItem.project_id)
    return project ? project.name : ''
  })

  const combinedProjectNames = projectNames?.join(', ')

  const modalText = props.date
    ? `Möchten Sie eine Wiedervorlage für das Datum ${format(props.date, 'dd.MM.yyyy')} erstellen?`
    : 'Möchten Sie eine Wiedervorlage erstellen?'

  const textForReminderCreation =
    props.date && combinedProjectNames
      ? `Widervorlage aufgrund des Abgesagten Termins vom ${format(props.date, 'dd.MM.yyyy')} zu den Projekt(en) ${combinedProjectNames}.`
      : 'Widervorlage aufgrund des Abgesagten Termins'

  const handleCreateClick = () => {
    if (props.pharmacyId && props.date) {
      openModal({ kind: AppModalKind.ReminderCreate, pharmacyId: props.pharmacyId, text: textForReminderCreation, date: props.date })
    }
  }

  return (
    <Modal kind={ModalKind.sm} title="Neue Widervorlage erstellen" onClose={closeModal} onBack={closeModal}>
      <div className={`flex flex-wrap w-full`}>
        <div className="flex flex-wrap w-full pb-2">
          <div className="w-full p-4">
            <div className="mt-2">
              <p className="text-l font-semibold text-black-500">{modalText}</p>
            </div>
          </div>
        </div>
        <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-between p-4 pt-4 pb-6 place-items-stretch">
          <Button kind={ButtonKind.secondary} onClick={closeModal}>
            {'Abbrechen'}
          </Button>
          <Button kind={ButtonKind.primary} onClick={handleCreateClick}>
            {'Erstellen'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ExportModal
