import { useEffect } from 'react'
import { Pharmacy, PharmacyStatus, useAbilities, parseUTC } from '@aposphaere/core-kit'
import {
  DeactivateIcon,
  EditIcon,
  IconButton,
  IconButtonKind,
  LocationProfileIcon,
  LockedForCallsIcon,
  PlusSignIcon,
  ProjectstatusIcon,
  ReminderIcon,
} from '@aposphaere/ui-components'
import { isFuture } from 'date-fns'
import { isToday } from 'date-fns/esm'
import React from 'react'
import { ModalKind, useModal } from '../../contexts/modalContext'
import { useAvailableForCallcenterMutation, useAppointmentsQuery } from '../../hooks/graphql'
import { Reminder } from '@aposphaere/core-kit'
import { Appointment } from '@aposphaere/core-kit'
import { deleteReminder, Response, convertToUTCForBackend } from '@aposphaere/core-kit'
import { useAuthenticatedMutation } from '../../hooks/useAuthenticatedMutation'
import { useTasksQuery } from '../../hooks/graphql'

type QuickActionProps = {
  pharmacy: Pharmacy
  onCreateAppointmentClick: () => void
  onOpenPharmacyDetails: () => void
}

const QuickActions: React.FunctionComponent<QuickActionProps> = ({ pharmacy, onCreateAppointmentClick, onOpenPharmacyDetails }) => {
  const { openModal } = useModal()
  const { mutate: setAvailableForCallcenter } = useAvailableForCallcenterMutation()
  const abilities = useAbilities()
  const { data: appointments } = useAppointmentsQuery()
  const deleteNoteMutation = useAuthenticatedMutation(deleteReminder)

  const onCreateReminder = () => {
    openModal({ kind: ModalKind.ReminderCreate, pharmacyId: pharmacy.id })
  }

  const onAddNote = () => {
    openModal({ kind: ModalKind.NoteCreate, pharmacyId: pharmacy.id })
  }

  const onDeactivatePharmacy = () => {
    openModal({ kind: ModalKind.PharmacyDeactivate, id: pharmacy.id, status: pharmacy.pharmacy_status })
  }

  const { data: tasks } = useTasksQuery()
  const tasksQuery = useTasksQuery()

  const excludedStatusIds = ['7', '12']
  const pharmacyAppointments: Appointment[] =
    appointments?.filter(
      (appointment) =>
        appointment.pharmacy?.id === pharmacy?.id &&
        `${appointment.appointmentType?.id || 0}` !== '4' &&
        !excludedStatusIds.includes(`${appointment.status?.id || 0}`) &&
        appointment.date &&
        isFuture(new Date(appointment.date)),
    ) || []

  const pharmacyTasks: Reminder[] = tasks?.filter((reminder) => reminder.pharmacy?.id === pharmacy?.id) || []

  const getMutation = (reminder: Reminder) => {
    let mutation: (() => Promise<Response<unknown>>) | null = null
    mutation = () => deleteNoteMutation({ id: reminder.id })
    return mutation
  }

  const onDeleteReminder = async (reminder: Reminder) => {
    const adjustedValues = {
      ...reminder,
      until: convertToUTCForBackend(reminder.until) ?? undefined,
    }
    const mutation = getMutation(adjustedValues)
    const reminderResponse = await mutation()
    await tasksQuery.refetch()
    if (reminderResponse?.errors !== undefined) {
      alert(reminderResponse.errors)
      return
    }
  }

  useEffect(() => {
    const deleteReminders = async () => {
      for (const reminder of pharmacyTasks || []) {
        if (pharmacyAppointments?.length) {
          await onDeleteReminder(reminder)
        }
      }
    }

    deleteReminders()
  }, [pharmacyAppointments])

  const additionalCss = pharmacyTasks?.filter((task) => task?.until && (isToday(parseUTC(task.until)) || isFuture(parseUTC(task.until)))).length
    ? 'text-yellow-600'
    : ''
  return (
    <div className="flex items-center">
      <IconButton kind={IconButtonKind.default} icon={<LocationProfileIcon />} onClick={onOpenPharmacyDetails} />
      <IconButton
        kind={IconButtonKind.default}
        icon={<ProjectstatusIcon />}
        onClick={() => openModal({ kind: ModalKind.ProjectsEdit, pharmacyId: pharmacy.id })}
      />
      {abilities.create_appointments && <IconButton kind={IconButtonKind.default} icon={<PlusSignIcon />} onClick={onCreateAppointmentClick} />}
      {abilities.create_tasks && (
        <IconButton additionalCss={additionalCss} kind={IconButtonKind.default} icon={<ReminderIcon />} onClick={onCreateReminder} />
      )}
      {abilities?.update_pharmacies_callcenter_access && (
        <IconButton
          kind={IconButtonKind.default}
          additionalCss={pharmacy.available_for_callcenter === false ? 'text-red-700' : ''}
          icon={<LockedForCallsIcon />}
          onClick={() => setAvailableForCallcenter({ id: pharmacy.id, available_for_callcenter: !pharmacy.available_for_callcenter })}
        />
      )}

      {abilities.create_notes && <IconButton kind={IconButtonKind.default} icon={<EditIcon />} onClick={onAddNote} />}
      {abilities.disable_pharmacies && (
        <IconButton
          kind={IconButtonKind.default}
          additionalCss={pharmacy.pharmacy_status === PharmacyStatus.Inactive ? 'text-red-700' : ''}
          icon={<DeactivateIcon />}
          onClick={onDeactivatePharmacy}
        />
      )}
    </div>
  )
}

export default QuickActions
