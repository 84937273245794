import { Appointment, formattedDateString, parseUTC, IRouteResponse, Pharmacy } from '@aposphaere/core-kit'
import React, { useContext, useMemo, useState } from 'react'
import { useAppointmentsQuery, useAppointmentTypesQuery, useCancelledStatusesIds, usePharmaciesQuery } from '../hooks/graphql'

interface IRoutePlannerContext {
  selectedDay: Date | undefined
  setSelectedDay: React.Dispatch<React.SetStateAction<Date | undefined>>

  routeResponse: IRouteResponse | null
  setRouteResponse: React.Dispatch<React.SetStateAction<IRouteResponse | null>>

  isShowRouteWithAllPharmacy: boolean
  setShowRouteWithAllPharmacy: React.Dispatch<React.SetStateAction<boolean>>

  sortedAppointmentsToday: Appointment[] | null
  pharmaciesHasAppointmentsToday: Pharmacy[] | null
  showSelectedDayOverview: boolean
  toggleShowSelectedDayOverview: () => void
  setShowSelectedDayOverview: React.Dispatch<React.SetStateAction<boolean>>

  sortedVisitsToday: Appointment[] | null
}

const RoutePlannerContext = React.createContext<IRoutePlannerContext | null>(null)
RoutePlannerContext.displayName = 'RoutePlannerContext'

export const RoutePlannerProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedDay, setSelectedDay] = useState<Date | undefined>(undefined)
  const [routeResponse, setRouteResponse] = useState<IRouteResponse | null>(null)
  const [isShowRouteWithAllPharmacy, setShowRouteWithAllPharmacy] = useState<boolean>(false)

  const { data: appointments } = useAppointmentsQuery()
  const appointmentTypesQuery = useAppointmentTypesQuery()
  const { data: pharmacies } = usePharmaciesQuery()
  const cancelledStatusIds = useCancelledStatusesIds()
  const appointmentTypes = appointmentTypesQuery.data?.appointmentTypes
  const { sortedAppointmentsToday, pharmaciesHasAppointmentsToday, sortedVisitsToday } = useMemo(() => {
    if (selectedDay && appointments && pharmacies) {
      const filteredAppointments = appointments.filter(
        (el) =>
          formattedDateString(el.date ? parseUTC(el.date) : new Date()) === formattedDateString(selectedDay) && el.pharmacy?.id && !el.is_deleted,
      )

      const sortedAppointments = filteredAppointments.sort((a, b) => ((a.date ? parseUTC(a.date) : '') > (b.date ? parseUTC(b.date) : '') ? 1 : -1))

      const idsPharmaciesHasAppointmentsToday = sortedAppointments
        .filter((each) => !cancelledStatusIds.includes(each?.status?.id?.toString() || ''))
        .map((el) => `${el?.pharmacy?.id || 0}`)

      const pharmaciesHasAppointments = pharmacies.reduce((res: Record<string, Pharmacy>, pharmacy) => {
        if (idsPharmaciesHasAppointmentsToday.includes(`${pharmacy.id}`)) {
          res[`${pharmacy.id}`] = pharmacy
        }
        return res
      }, {})

      const VISIT_APPOINTMENT_TYPE_FRAGMENT = 'besuch'
      const visitAppointmentType = appointmentTypes?.find((type) => type.label?.toLowerCase()?.includes(VISIT_APPOINTMENT_TYPE_FRAGMENT))

      return sortedAppointments.length && Object.values(pharmaciesHasAppointments).length
        ? {
            sortedAppointmentsToday: selectedDay
              ? sortedAppointments.filter((eachAppointment) => {
                  const appointTypeId = eachAppointment.appointmentType?.id
                  return appointTypeId !== visitAppointmentType?.id && !cancelledStatusIds.includes(eachAppointment?.status?.id?.toString() || '')
                })
              : sortedAppointments,
            pharmaciesHasAppointmentsToday: Object.values(pharmaciesHasAppointments),
            sortedVisitsToday: sortedAppointments.filter((eachAppointment) => {
              const appointTypeId = eachAppointment.appointmentType?.id
              return appointTypeId === visitAppointmentType?.id && !cancelledStatusIds.includes(eachAppointment?.status?.id?.toString() || '')
            }),
          }
        : {
            sortedAppointmentsToday: null,
            pharmaciesHasAppointmentsToday: null,
            sortedVisitsToday: null,
          }
    }
    return {
      sortedAppointmentsToday: null,
      pharmaciesHasAppointmentsToday: null,
      sortedVisitsToday: null,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay, appointments, pharmacies])

  const showRouteModal = !routeResponse || !sortedAppointmentsToday || !pharmaciesHasAppointmentsToday

  const [showSelectedDayOverview, setShowSelectedDayOverview] = useState(showRouteModal)

  const toggleShowSelectedDayOverview = () => {
    setShowSelectedDayOverview((prev) => !prev)
  }

  return (
    <RoutePlannerContext.Provider
      value={{
        showSelectedDayOverview,
        toggleShowSelectedDayOverview,
        selectedDay,
        setSelectedDay,
        routeResponse,
        setRouteResponse,
        isShowRouteWithAllPharmacy,
        setShowRouteWithAllPharmacy,
        sortedAppointmentsToday,
        pharmaciesHasAppointmentsToday,
        setShowSelectedDayOverview,
        sortedVisitsToday,
      }}
    >
      {children}
    </RoutePlannerContext.Provider>
  )
}

export const useRoutePlanner = () => {
  const context = useContext(RoutePlannerContext)
  if (!context) {
    throw new Error("Provider not found: Attemting to use the RoutePlannerContext, without it's provider")
  }
  return context
}
