import { useAuth } from '@aposphaere/core-kit'
import React, { useEffect, useMemo } from 'react'
import { useCrmContext } from '../../../contexts/crmContext'
import { useDashboardContext } from '../../../contexts/dashboardContext'
import { WHOLE_GERMANY_ID } from '../constants'
import { AgentSelectorItem } from './AgentSelectorItem'

export type AgentSelectorItemType = {
  id: string
  clusterName: string
  agentName: string
}

export const AgentSelector = () => {
  const { user } = useAuth()
  const { isUserManager, setActiveClusterId } = useCrmContext()
  const {
    GENERAL: { clusterSelected, setClusterSelected },
  } = useDashboardContext()

  const pharmacyClusters =
    user?.pharmacy_clusters?.map((cluster) => ({ id: cluster.id.toString(), clusterName: cluster.name!, agentName: cluster.agent?.name! })) || []

  const firstPharmacyCluster = pharmacyClusters[0]?.id

  const allClustersCombined = useMemo(() => ({ id: WHOLE_GERMANY_ID, clusterName: 'Alle Regionen', agentName: 'Deutschlandweit' }), [])

  const selectorItems = useMemo(() => {
    if (isUserManager) {
      return [allClustersCombined, ...pharmacyClusters]
    }
    return pharmacyClusters
  }, [isUserManager, pharmacyClusters, allClustersCombined])

  useEffect(() => {
    if (!clusterSelected) {
      if (isUserManager) {
        setClusterSelected(allClustersCombined.id)
      } else {
        setClusterSelected(firstPharmacyCluster)
        setActiveClusterId(firstPharmacyCluster)
      }
    }
  }, [isUserManager, firstPharmacyCluster, allClustersCombined.id, setClusterSelected, clusterSelected, setActiveClusterId])

  return (
    <div className="w-1/5 lg:w-fit border-r overflow-y-scroll">
      {selectorItems.map((selectorData) => (
        <AgentSelectorItem key={selectorData.id} itemData={selectorData} />
      ))}
    </div>
  )
}
