import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Checkbox } from '@aposphaere/ui-components'
import { DataForGraphType } from '../../../contexts/dashboardContext'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const AppointmentGraph = ({ dataForGraph }: { dataForGraph: DataForGraphType[] }) => {
  const chartRef = useRef()
  const [showJustTrainingAppointments, setShowJustTrainingAppointments] = useState(false)

  const initialDataSet = useMemo(
    () => [
      {
        label: 'Abgesagt',
        data: dataForGraph.map(({ cancelledCount }) => cancelledCount),
        backgroundColor: '#84B226',
      },
      {
        label: 'Schulungen',
        data: dataForGraph.map(({ trainingDoneCount }) => trainingDoneCount),
        backgroundColor: '#FD5C1C',
      },
      {
        label: 'Avisiert',
        data: dataForGraph.map(({ bookedCount }) => bookedCount),
        backgroundColor: '#2E8F43',
      },
      {
        label: 'Besuch ohne Schulung',
        data: dataForGraph.map(({ visitCount }) => visitCount),
        backgroundColor: '#2497D2',
      },
    ],
    [dataForGraph],
  )

  const [dataSet, setDataSet] = useState(initialDataSet)

  useEffect(() => {
    if (showJustTrainingAppointments) {
      const justTrainings = initialDataSet.filter(({ label }) => label === 'Schulungen')
      setDataSet(justTrainings)
    } else {
      setDataSet(initialDataSet)
    }
  }, [showJustTrainingAppointments, initialDataSet])

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Terminstatus-Übersicht',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
  }

  const data = {
    labels: dataForGraph.map(({ label }) => label),
    datasets: dataSet,
    tooltips: {},
  }

  return (
    <div className="flex flex-col py-3 pr-3 items-end">
      <Checkbox
        name="justTrainings"
        label="Nur Schulungstermine anzeigen"
        checked={showJustTrainingAppointments}
        onChange={(e) => {
          setShowJustTrainingAppointments(e.currentTarget.checked)
        }}
      />
      <Bar options={options} data={data} ref={chartRef} height={80} />
    </div>
  )
}
