import React from 'react'
import Filter from './Filter'
import AgentSelector from './AgentSelector'
import { useCrmContext } from '../../contexts/crmContext'

import DashboardContent from './DashboardContent'

export const DashboardPage = () => {
  const { isUserManager } = useCrmContext()

  return (
    <>
      <Filter />
      <div className="flex w-full h-dashboard-content overflow-hidden">
        {isUserManager && <AgentSelector />}
        <div className="flex-1 overflow-y-scroll">
          <DashboardContent />
        </div>
      </div>
    </>
  )
}
