import { Appointment, Pharmacy } from '@aposphaere/core-kit'
import { IFilterByOption } from '@aposphaere/ui-components'
import React, { createContext, PropsWithChildren, useContext, useMemo, useCallback, useReducer } from 'react'
import { VISIT_APPOINTMENT_TYPE_FRAGMENT } from '../constants'
import { useAppointmentsQuery, usePharmaciesQuery, useQuartersQuery } from '../hooks/graphql'
import { useFinishedPresentationsQuery } from '../hooks/graphql/finishedPresentation'
import { filterMapReducer, initialMapFilterState, IMapFilterState, IMapFilterAction } from './reducers/mapFilterReducer'
import { useCrmContext } from './crmContext'
import { min, max, format, parseISO } from 'date-fns'

export interface IPharmacyFilterContext {
  filterState: IMapFilterState
  dispatchFilterState: React.Dispatch<IMapFilterAction>
  filteredPharmacies: Pharmacy[]
  areProjectsSelected: boolean
}

export const PharmacyFilterContext = createContext<IPharmacyFilterContext | null>(null)

const PharmacyFilterProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const quartersQuery = useQuartersQuery()

  const startingDates = quartersQuery.data?.filter((quarter) => quarter?.from).map((quarter) => parseISO(quarter!.from!))
  const endingDates = quartersQuery.data?.filter((quarter) => quarter?.to).map((quarter) => parseISO(quarter!.to!))

  const minDate = min(startingDates || [])
  const maxDate = max(endingDates || [])
  const date = { from: format(minDate, 'yyyy-MM-dd'), to: format(maxDate, 'yyyy-MM-dd') }

  const { data: pharmacies } = usePharmaciesQuery()
  const { data: appointments } = useAppointmentsQuery({ date: date })
  const { activeCluster } = useCrmContext()
  const { data: finishedPresentationsFromQuery } = useFinishedPresentationsQuery({ clusterId: activeCluster.id.toString(), date: date })
  const [filterState, dispatchFilterState] = useReducer(filterMapReducer, initialMapFilterState)

  const areProjectsSelected = filterState.selectedProjects.length > 0
  const areFiltersActive = areProjectsSelected || filterState.searchTerm

  const fitsOnlyPriority = useCallback(
    (pharmacy: Pharmacy, pharmacyProjects: { id: string; is_priority: number }[]) => {
      if (!filterState.isOnlyPriority) {
        return true
      }

      const projectWithPrio = pharmacy.projects?.find((project) => project.pivot?.is_priority === 1)

      const isprojectWithPrio = projectWithPrio ? pharmacyProjects.some(({ id }) => id === String(projectWithPrio.id)) : false

      return isprojectWithPrio
    },
    [filterState.isOnlyPriority],
  )

  const fitsTerminatedProjects = useCallback(
    (pharmacyProjects: { id: string; hasStatus: boolean }[]) => {
      if (filterState.includesTerminatedProjects) {
        return true
      }
      const isEveryProjectTerminated = pharmacyProjects.every(({ hasStatus }) => hasStatus)
      return !isEveryProjectTerminated
    },
    [filterState.includesTerminatedProjects],
  )

  const fitsAvailableForCallcenter = useCallback(
    (pharmacy: Pharmacy) => {
      if (!filterState.isAvailableForCallcenter) {
        return true
      }
      return pharmacy.available_for_callcenter
    },
    [filterState.isAvailableForCallcenter],
  )

  const fitsOnlySellout = useCallback(
    (pharmacy: Pharmacy) => {
      if (!filterState.isSellout) {
        return true
      }
      return pharmacy.aktionsapo
    },
    [filterState.isSellout],
  )

  const fitsIsADPriority = useCallback(
    (pharmacy: Pharmacy, pharmacyProjects: { id: string; is_priority: number }[]) => {
      if (!filterState.isADPriority) {
        return true
      }

      const projectWithADPrio = pharmacy.projects?.find((project) => project.pivot?.is_priority === 2)

      const isProjectWithADPrio = projectWithADPrio ? pharmacyProjects.some(({ id }) => id === String(projectWithADPrio.id)) : false

      return isProjectWithADPrio
    },
    [filterState.isADPriority],
  )

  const fitsHasAppointmentsScheduled = useCallback(
    (pharmacy: Pharmacy, pharmacyProjects: { id: string; hasStatus: boolean }[]) => {
      if (!filterState.onlyWithScheduledAppointments) {
        return true
      }
      const projectsWithAppointments =
        appointments
          ?.filter(
            ({ appointmentType, pharmacy: appointmentPharmacy }) =>
              !appointmentType?.label_short?.toLowerCase().includes(VISIT_APPOINTMENT_TYPE_FRAGMENT) &&
              pharmacy?.id.toString() === appointmentPharmacy?.id.toString(),
          )
          .reduce((acc: string[], curr: Appointment) => {
            const projectIds = curr.order_items.map(({ project_id }) => project_id.toString())
            return acc.concat(projectIds)
          }, []) ?? []

      const isProjectBooked = pharmacyProjects.some(({ id: projectId }) => projectsWithAppointments.includes(projectId))

      return isProjectBooked
    },
    [filterState.onlyWithScheduledAppointments, appointments],
  )

  const fitsOpenPotential = useCallback(
    (pharmacy: Pharmacy, pharmacyProjects: { id: string; hasStatus: boolean }[]) => {
      if (!filterState.isOpenPotential) {
        return true
      }
      const projectsWithAppointments =
        appointments
          ?.filter(({ pharmacy: appointmentPharmacy }) => pharmacy?.id.toString() === appointmentPharmacy?.id.toString())
          .reduce((acc: string[], curr: Appointment) => {
            const projectIds = curr.order_items.map(({ project_id }) => project_id.toString())
            return acc.concat(projectIds)
          }, []) ?? []

      const finishedPresentationsWithProjects =
        finishedPresentationsFromQuery
          ?.filter(({ pharmacy: appointmentPharmacy }) => pharmacy?.id.toString() === appointmentPharmacy?.id.toString())
          .map(({ project }) => project?.id.toString()) ?? []

      const isProjectBooked = pharmacyProjects.some(
        ({ id: projectId }) => projectsWithAppointments.includes(projectId) || finishedPresentationsWithProjects.includes(projectId),
      )

      return !isProjectBooked
    },
    [filterState.isOpenPotential, appointments, finishedPresentationsFromQuery],
  )

  const fitsSelectFilter = useCallback(
    (pharmacy: Pharmacy) => {
      if (!areProjectsSelected) {
        return true
      }

      const relevantPharmacyProjects = pharmacy.projects.filter(({ id: projectId }) => filterState.selectedProjects.includes(projectId.toString()))

      if (!relevantPharmacyProjects.length) {
        return false
      }

      const pharmacyProjects = relevantPharmacyProjects.map((project) => ({
        id: project.id.toString(),
        hasStatus: !!project.pivot?.status_id,
        is_priority: project.pivot.is_priority,
      }))
      if (filterState.filterByOption === IFilterByOption.AND) {
        const hasEveryProjectSelected = !!filterState.selectedProjects.every((projectId) => !!pharmacyProjects.find(({ id }) => id === projectId))

        const hasEveryValidProjectSelected = !!filterState.selectedProjects.every(
          (projectId) => !!pharmacyProjects.find(({ id, hasStatus }) => id === projectId && hasStatus === false),
        )

        if (!hasEveryProjectSelected) {
          return false
        }

        if (!hasEveryValidProjectSelected && !filterState.includesTerminatedProjects) {
          return false
        }
      }

      return (
        fitsTerminatedProjects(pharmacyProjects) &&
        fitsHasAppointmentsScheduled(pharmacy, pharmacyProjects) &&
        fitsOpenPotential(pharmacy, pharmacyProjects) &&
        fitsIsADPriority(pharmacy, pharmacyProjects) &&
        fitsAvailableForCallcenter(pharmacy) &&
        fitsOnlySellout(pharmacy) &&
        fitsOnlyPriority(pharmacy, pharmacyProjects)
      )
    },
    [
      filterState.selectedProjects,
      fitsHasAppointmentsScheduled,
      fitsAvailableForCallcenter,
      fitsOnlySellout,
      fitsIsADPriority,
      fitsTerminatedProjects,
      fitsOpenPotential,
      fitsOnlyPriority,
      filterState.filterByOption,
      areProjectsSelected,
    ],
  )

  const fitsSearchTerm = useCallback(
    (pharmacy: Pharmacy) => {
      if (!filterState.searchTerm) {
        return true
      }

      const lowerCaseSearchTerm = filterState.searchTerm.trim().toLowerCase()
      const ifFitsName = pharmacy?.name?.toLowerCase().includes(lowerCaseSearchTerm)
      const ifFitsOkId = pharmacy?.okid?.toLowerCase().includes(lowerCaseSearchTerm)
      const ifFitsId = Number(pharmacy?.id) === Number(lowerCaseSearchTerm)
      return ifFitsName || ifFitsOkId || ifFitsId
    },
    [filterState.searchTerm],
  )

  const filteredPharmacies = useMemo(() => {
    if (!areFiltersActive) {
      return pharmacies ?? []
    }
    return pharmacies?.filter((pharmacy) => fitsSearchTerm(pharmacy) && fitsSelectFilter(pharmacy)) ?? []
  }, [pharmacies, fitsSearchTerm, fitsSelectFilter, areFiltersActive])

  const value = useMemo(
    () => ({
      filterState,
      dispatchFilterState,
      filteredPharmacies,
      areProjectsSelected,
    }),
    [filterState, dispatchFilterState, filteredPharmacies, areProjectsSelected],
  )

  return <PharmacyFilterContext.Provider value={value}>{children}</PharmacyFilterContext.Provider>
}

export const usePharmacyFilterContext = () => {
  const context = useContext(PharmacyFilterContext)
  if (!context) {
    throw new Error("Provider not found: Attemting to use the filter context, without it's provider")
  }
  return context
}

export default PharmacyFilterProvider
