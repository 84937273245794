import { Pharmacy } from '@aposphaere/core-kit'
import React from 'react'
import { usePharmacyFilterContext } from '../../contexts/filterContext'
import { IMapFilterType } from '../../contexts/reducers/mapFilterReducer'

type Props = {
  pharmacy?: Pharmacy
  setSelectedPharmacy: (id: number | undefined) => void
}

const PharmacyInfo = ({ title, pharmacy, setSelectedPharmacy }: Props & { title: string }) => {
  const { dispatchFilterState } = usePharmacyFilterContext()

  const handleOnClick = () => {
    dispatchFilterState({ type: IMapFilterType.CLEAR })
    setSelectedPharmacy(pharmacy?.id)
  }

  if (!pharmacy) {
    return null
  }

  return (
    <div className="flex cursor-pointer" onClick={handleOnClick}>
      <div className="mr-1 font-medium">{title}</div>
      <div>
        <div>{`${pharmacy.name} (${pharmacy.id})`} </div>
        <div>{pharmacy.address.address_name} </div>
        <div>
          {pharmacy.address.zipcode} {pharmacy.address.city}
        </div>
      </div>
    </div>
  )
}

export const BranchesOverview = ({ pharmacy, setSelectedPharmacy }: Props) => (
  <div className="flex flex-wrap gap-3 max-w-sm">
    {<PharmacyInfo title="Haupt:" pharmacy={pharmacy?.parent} setSelectedPharmacy={setSelectedPharmacy} />}
    {pharmacy?.branches?.map((branch) => (
      <PharmacyInfo pharmacy={branch} title="Filiale:" setSelectedPharmacy={setSelectedPharmacy} />
    ))}
  </div>
)
