import { useQuery } from 'react-query'
import { useGqlClient, getSurveyAnswerCount } from '@aposphaere/core-kit'
import { QueryOptions } from './utils'
import { useCrmContext } from '../../contexts/crmContext'

type SurveyAnswerCountQueryProps = {
  clusterId?: string
  date?: { from: string; to: string }
  options?: QueryOptions<number>
  enabled?: boolean
}

export const useSurveyAnswerCountQuery = (props?: SurveyAnswerCountQueryProps) => {
  const gqlClient = useGqlClient()
  const { activeCluster } = useCrmContext()
  const clusterId = props?.clusterId || activeCluster.id
  const variables = { pharmacyClusterId: clusterId, date: props?.date }
  return useQuery(
    ['survey_answer_count', `cluster-${props?.clusterId}`, `dateRange-${JSON.stringify(props?.date)}`],
    async () => {
      const data = await gqlClient.request<{ getSurveyAnswerCount: { total_count: number } }>(getSurveyAnswerCount, variables)
      return data?.getSurveyAnswerCount?.total_count ?? 0
    },
    { staleTime: Infinity, enabled: props?.enabled ?? true, ...props?.options },
  )
}
