import { AppointmentOrderItem, Project } from '@aposphaere/core-kit'
import { OrderDetailsItem } from '../CalendarPanelAppointment/CalendarPanelContext'

export const getDurationPerTraining = (orderItems: AppointmentOrderItem[] | OrderDetailsItem[], projects: Project[]) => {
  let totalDurationInSeconds = 0

  orderItems.forEach((order) => {
    const project = projects.find((proj) => proj.id.toString() === order.project_id.toString())
    totalDurationInSeconds += Number(project?.booked_time_in_seconds) * Number(order.amount)
  })

  return totalDurationInSeconds
}
