import React from 'react'
import { InputLabel } from '@aposphaere/ui-components'
import useProjectStatus from './utils/useProjectStatus'
import { useStatusesForProject } from '../../hooks/graphql'

interface Props {
  pharmacyId: number
}

function ProjectList({ pharmacyId }: Props) {
  const { validActivePayload } = useProjectStatus(pharmacyId)
  const statusesForProject = useStatusesForProject()

  return (
    <div className="flex flex-wrap w-full">
      <div className="w-full p-4">
        <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-7 pb-2 mt-4">
          <div className="sm:col-span-3">
            <InputLabel>{'Projektname:'}</InputLabel>
          </div>
          <div className="sm:col-span-4">
            <InputLabel>{'Status:'}</InputLabel>
          </div>
        </div>
        <ul className="w-full">
          {validActivePayload?.map(({ project, selectedStatusId, statusChangeHandler }) => (
            <li key={project.name} className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-7 border-b border-gray-400 last:border-0 py-2.5">
              <div className="sm:col-span-3 flex items-center">
                <div className="flex w-full rounded-md">{project.name}</div>
              </div>
              <div className="sm:col-span-4">
                <div className="flex w-full rounded-md">
                  <select
                    onChange={statusChangeHandler}
                    value={selectedStatusId}
                    className="w-full form-select font-body h-12 text-gray-900 bg-gray-100 block rounded-md py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400"
                  >
                    {statusesForProject.map((status) => (
                      <option key={status.id} value={status.id}>
                        {status.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ProjectList
