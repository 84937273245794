import { FederalStateHoliday, federalStateHolidaysClusterQuery, useGqlClient } from '@aposphaere/core-kit'
import { QueryOptions, useQuery } from 'react-query'
import { useCrmContext } from '../../contexts/crmContext'

type Props = {
  clusterId?: string
  date: { from: string | Date | undefined; to: string | Date | undefined }
  options?: QueryOptions<FederalStateHoliday[]>
  enabled?: boolean
}

export const useStateHolidaysClusterQuery = (props: Props) => {
  const gqlClient = useGqlClient()
  const { date } = props
  const { activeCluster } = useCrmContext()

  return useQuery(
    ['federal-state-holidays-cluster', `cluster-${activeCluster.id}`, `dateRange-${JSON.stringify(date)}`],
    async () => {
      const data = await gqlClient.request(federalStateHolidaysClusterQuery, { date, pharmacyClusterId: activeCluster.id })
      return data?.stateHolidaysCluster || []
    },
    { staleTime: Infinity, enabled: props?.enabled ?? true, ...props?.options },
  )
}
