import { Button, ButtonKind, toast } from '@aposphaere/ui-components'
import { isAfter } from 'date-fns'
import React, { useCallback, useState } from 'react'
import DatePickerComponent, { IDatePickerProps } from '../../../components/DatePicker'
import { useCrmContext } from '../../../contexts/crmContext'
import { DateRangeSelector } from './DateRangeSelector'
import { useDashboardContext } from '../../../contexts/dashboardContext'

const DatePicker = ({ title, ...props }: IDatePickerProps & { title: string }) => (
  <div className="flex w-1/5 rounded-md justify-items-start items-center mr-4">
    <span className="text-sm font-medium tracking-wide uppercase mr-2">{title}</span>
    <DatePickerComponent {...props} />
  </div>
)

export const Filter = () => {
  const { isUserManager } = useCrmContext()
  const {
    GENERAL: { dates, setDates },
  } = useDashboardContext()
  const [internalDates, setInternalDates] = useState(dates)

  const handleOnStartDateChange = useCallback(
    (startDate: Date) => {
      const newDate = { ...internalDates, startDate }
      setInternalDates(newDate)
    },
    [internalDates, setInternalDates],
  )

  const handleOnEndDateChange = useCallback(
    (endDate: Date) => {
      const newDate = { ...internalDates, endDate }
      setInternalDates(newDate)
    },
    [internalDates, setInternalDates],
  )

  const onCalculateDashboardValues = useCallback(() => {
    const { startDate, endDate } = internalDates
    if (isAfter(startDate, endDate)) {
      toast.show({
        headline: 'Das Enddatum liegt vor dem Startdatum',
        type: 'error',
      })
      return
    }
    setDates(internalDates)
  }, [internalDates, setDates])

  const wrapperStyle = `flex w-1/4 justify-${isUserManager ? 'between' : 'end'} w-full px-3 xl:px-6 items-center py-2 border-b`

  return (
    <div className={wrapperStyle}>
      {isUserManager && <div className="uppercase text-base">Team dashboard</div>}
      <div className="flex transform scale-80 justify-end items-center -mr-16">
        <div className="uppercase mr-6">Zeitfenster</div>
        <DateRangeSelector setDates={setInternalDates} />
        <DatePicker title="Von:" value={internalDates.startDate} dayPickerProps={{ onDayClick: handleOnStartDateChange }} />
        <DatePicker title="Bis:" value={internalDates.endDate} dayPickerProps={{ onDayClick: handleOnEndDateChange }} />
        <Button kind={ButtonKind.primary} onClick={onCalculateDashboardValues}>
          Berechnen
        </Button>
      </div>
    </div>
  )
}
