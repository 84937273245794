import { parseUTC } from '@aposphaere/core-kit'
import { eachDayOfInterval, format, intervalToDuration, isAfter, isFuture, parseISO } from 'date-fns'
import { useMemo } from 'react'
import { TRAINING_BOOKED, TRAINING_COMPLETED, VISIT_APPOINTMENT_TYPE_FRAGMENT } from '../constants'
import { DashboardStructureType, DateType, SummaryPerProjectDataType } from '../contexts/dashboardContext'
import {
  useAppointmentsQuery,
  useCancelledStatusesIds,
  useOfftimesQuery,
  usePharmaciesQuery,
  usePharmacyClustersQuery,
  useProjectsQuery,
  useStateHolidaysClusterQuery,
  useStatusesQuery,
  useGoalsQuery,
} from './graphql'
import { useOfftimesSummary } from './useOfftimesSummary'
import { getAverageValues } from '../utils/dashboardUtils'
import { useWorkingDays } from './useWorkingDays'
import { WHOLE_GERMANY_ID } from '../pages/DashboardPage/constants'
import { useSurveyAnswerCountQuery } from './graphql/survey'
import { useFinishedPresentationsQueryTrainer } from './graphql/finishedPresentation'
import { ClusterQuarterProjectGoal } from '@aposphaere/core-kit'
import { useCrmContext } from '../contexts/crmContext'

type AppointmentSummaryPerProjectType = Omit<
  SummaryPerProjectDataType,
  | 'projectPharmacyCount'
  | 'name'
  | 'averageDuration'
  | 'participantCount'
  | 'projectGoal'
  | 'totalTrainingCount'
  | 'gapTrainingCount'
  | 'averageTotalTrainingCount'
  | 'averageTrainingGoal'
  | 'averageGapTrainingCount'
  | 'goalAchievement'
> & {
  pharmaciesReceivedTrainingArray: string[]
  bookedTimePerProject?: string
}
type ParticipantCountPerProjectType = Pick<SummaryPerProjectDataType, 'id' | 'participantCount'> & { durationsArray: number[] }

export const useDashboardSummaryPerCluster = (clusterSelected: string, dates: DateType) => {
  const variables = {
    clusterId: clusterSelected,
    enabled: clusterSelected !== WHOLE_GERMANY_ID,
    date: { from: format(dates.startDate, 'yyyy-MM-dd'), to: format(dates.endDate, 'yyyy-MM-dd') },
  }
  const { activeCluster } = useCrmContext()
  const presentationVariables = {
    date: { from: format(dates.startDate, 'yyyy-MM-dd'), to: format(dates.endDate, 'yyyy-MM-dd') },
    enabled: clusterSelected !== WHOLE_GERMANY_ID,
    trainerId: activeCluster.agent?.id,
  }

  const { data: pharmacies, isLoading: isPharmaciesLoading } = usePharmaciesQuery({ clusterId: clusterSelected })
  const { data: statuses, isLoading: isStatusesLoading } = useStatusesQuery()
  const { data: appointmentsFromQuery, isLoading: isAppointmentsLoading } = useAppointmentsQuery({ ...variables })
  const { data: publicHolidaysData, isLoading: isPublicHolidaysLoading } = useStateHolidaysClusterQuery({ ...variables })
  const { data: offtimes, isLoading: isOfftimesLoading } = useOfftimesQuery({ ...variables })
  const { data: clusters } = usePharmacyClustersQuery()
  const { data: pharmacies_answered_question } = useSurveyAnswerCountQuery({ ...variables })
  const { data: goals } = useGoalsQuery({ ...variables })
  const cancelledStatusIds = useCancelledStatusesIds()
  const isLoading = isAppointmentsLoading || isOfftimesLoading || isStatusesLoading || isPharmaciesLoading || isPublicHolidaysLoading
  const clusterAgentId = clusters?.find((cluster) => (activeCluster.agent?.id || clusterSelected.toString()) === cluster.agent?.id)?.agent?.id

  const { data: projects } = useProjectsQuery()
  const { data: finishedPresentationsFromQuery } = useFinishedPresentationsQueryTrainer({ ...presentationVariables })

  const activeProjects =
    projects?.filter(({ start_date, end_date }) => {
      if (isAfter(dates.startDate, new Date(end_date)) || isAfter(new Date(start_date), dates.endDate)) {
        return false
      }
      return true
    }) ?? []

  // get active projects -> id, name, min_time_in_seconds

  const graphLabels = useMemo(
    () =>
      eachDayOfInterval({ start: dates.startDate, end: dates.endDate }).map((date) => ({
        label: format(date, 'dd.MM.yyyy'),
        cancelledCount: 0,
        bookedCount: 0,
        visitCount: 0,
        trainingDoneCount: 0,
      })),
    [dates],
  )

  const {
    offtimesUntilTodayCount,
    futureOfftimesCount,
    offtimeCountsPerType: { homeOfficeCount, vacationCount, privateCount, meetingsCount, sickCount, vacantCount },
  } = useOfftimesSummary(offtimes)

  const { totalDaysInField, daysInFieldUntilToday, daysInFieldLeft, totalWorkingDays } = useWorkingDays({
    dates,
    publicHolidaysData: publicHolidaysData ?? [],
    offtimesUntilTodayCount,
    futureOfftimesCount,
  })

  const summarizeGoals = (goals: ClusterQuarterProjectGoal[] | undefined) => {
    const summary: Record<number, number> = {}

    goals?.forEach((item) => {
      const projectId = item.project_id
      const goal = item.adjusted_goal

      if (summary[projectId]) {
        summary[projectId] += goal
      } else {
        summary[projectId] = goal
      }
    })

    return summary
  }
  const summarizedGoals = summarizeGoals(goals)

  const trainingFinishedStatusId = statuses?.forAppointments.find(({ label }) => label?.toLowerCase() === TRAINING_COMPLETED)?.id
  const trainingBookedStatusId = statuses?.forAppointments.find(({ label }) => label?.toLowerCase() === TRAINING_BOOKED)?.id

  const summaryData = useMemo(() => {
    /* APPOINTMENTS */
    let appointmentsCompleted = 0
    let visitsCompleted = 0
    let appointmentsBooked = 0
    let visitsBooked = 0
    let totalAppointmentsCount = 0
    let pastCancelledTrainings = 0
    let appointmentsCreatedByAgent = 0

    /* PHARMACIES */
    let bookedPharmaciesArray: string[] = []
    let trainedPharmaciesArray: string[] = []
    let bookedForFuturePharmaciesArray: string[] = []
    let pharmaciesWithCancelledAppointmentsArray: string[] = []

    /* TRAINING SETS */
    let pastTrainingSets = 0
    let pastRepeatedTrainingSets = 0

    /* SLOTS */
    let totalSlots = 0
    let completedSlots = 0
    let bookedSlots = 0
    let bookedOrCompltedAppointments = 0

    /* PARTICIPANTS */
    let totalParticipantCount = 0
    let totalCompletedTrainingAppointmentCount = 0

    /* PROJECTS */
    let appointmentSummaryPerProject: AppointmentSummaryPerProjectType[] = []
    let participantCountPerProject: ParticipantCountPerProjectType[] = []

    /* DATA FOR GRAPH */
    let dataForGraph = [...graphLabels]

    appointmentsFromQuery?.forEach((appointment) => {
      const appointmentDate = parseUTC(appointment.date!)
      const isVisit = !!appointment.appointmentType?.label?.toLowerCase().includes(VISIT_APPOINTMENT_TYPE_FRAGMENT)
      const isCancelled = cancelledStatusIds.includes(appointment.status_id?.toString()!)
      const isFutureAppointment = isFuture(appointmentDate)
      const isTrainingCompleted = appointment.status_id?.toString() === trainingFinishedStatusId?.toString()
      const isTrainingBooked = appointment.status_id?.toString() === trainingBookedStatusId?.toString() && !isVisit
      const appointmentCreatorId = appointment.creator?.id

      /* APPOINTMENTS */
      if (!isCancelled) {
        if (isVisit) {
          isFutureAppointment ? visitsBooked++ : visitsCompleted++
        } else {
          isFutureAppointment ? appointmentsBooked++ : appointmentsCompleted++
        }
        if (clusterAgentId?.toString() === appointmentCreatorId?.toString()) {
          appointmentsCreatedByAgent++
        }
      }

      if (isCancelled && !isFutureAppointment) {
        pastCancelledTrainings++
      }

      /* PHARMACIES */
      const pharmacyId = appointment.pharmacy?.okid
      if (!isVisit && pharmacyId) {
        totalAppointmentsCount++
        bookedPharmaciesArray.push(pharmacyId)
        if (isTrainingCompleted) {
          trainedPharmaciesArray.push(pharmacyId)
        }
        if (isFutureAppointment && !isCancelled) {
          bookedForFuturePharmaciesArray.push(pharmacyId)
        }
        if (isCancelled) {
          pharmaciesWithCancelledAppointmentsArray.push(pharmacyId)
        }
      }

      /* PROJECTS */
      appointment.order_items.forEach((project) => {
        const indexInProjectList = appointmentSummaryPerProject.findIndex((item) => item.id.toString() === project.project_id.toString())
        const bookedFromTomorrow = isTrainingBooked && isFutureAppointment
        const isConsecutiveTraining = Number(project.amount) > 1 && isTrainingCompleted

        if (indexInProjectList === -1) {
          const projectCountItem = {
            trainingCompletedCount: isTrainingCompleted ? Number(project.amount ?? 0) : 0,
            id: project.project_id.toString(),
            pharmaciesReceivedTrainingArray: isTrainingCompleted ? [appointment.pharmacy?.okid!] : [],
            pharmaciesReceivedTrainingCount: isTrainingCompleted ? 1 : 0,
            bookedFromTomorrow: bookedFromTomorrow ? 1 : 0,
            cancelledCount: isCancelled ? 1 : 0,
            consecutiveTrainingCount: isConsecutiveTraining ? 1 : 0,
            bookedTimePerProject: '',
          }
          appointmentSummaryPerProject.push(projectCountItem)
        } else {
          const itemToUpdate = appointmentSummaryPerProject[indexInProjectList]
          const shouldPharmacyBeCounted = !itemToUpdate.pharmaciesReceivedTrainingArray.includes(appointment.pharmacy?.okid!) && isTrainingCompleted
          appointmentSummaryPerProject.splice(indexInProjectList, 1, {
            ...itemToUpdate,
            trainingCompletedCount: isTrainingCompleted
              ? itemToUpdate.trainingCompletedCount + Number(project.amount)
              : itemToUpdate.trainingCompletedCount,
            pharmaciesReceivedTrainingArray: shouldPharmacyBeCounted
              ? [...itemToUpdate.pharmaciesReceivedTrainingArray, appointment.pharmacy?.okid!]
              : itemToUpdate.pharmaciesReceivedTrainingArray,
            pharmaciesReceivedTrainingCount: shouldPharmacyBeCounted
              ? itemToUpdate.pharmaciesReceivedTrainingCount + 1
              : itemToUpdate.pharmaciesReceivedTrainingCount,
            bookedFromTomorrow: bookedFromTomorrow ? itemToUpdate.bookedFromTomorrow + 1 : itemToUpdate.bookedFromTomorrow,
            cancelledCount: isCancelled ? itemToUpdate.cancelledCount + 1 : itemToUpdate.cancelledCount,
            consecutiveTrainingCount: isConsecutiveTraining ? itemToUpdate.consecutiveTrainingCount + 1 : itemToUpdate.consecutiveTrainingCount,
          })
        }
      })

      /* TRAINING SETS */
      if (isTrainingCompleted) {
        totalCompletedTrainingAppointmentCount++
        pastTrainingSets =
          pastTrainingSets +
          appointment.order_items.reduce((acc, curr) => {
            const noOfSets = Number(curr.amount)
            if (noOfSets > 1) {
              pastRepeatedTrainingSets++
            }
            return (acc += noOfSets)
          }, 0)
        appointment.finished_presentations?.forEach((presentation) => {
          const durationObject = intervalToDuration({
            start: parseUTC(presentation.starttime),
            end: parseUTC(presentation.endtime),
          })
          const duration = (durationObject?.hours ?? 0) * 60 + (durationObject?.minutes ?? 0)
          totalParticipantCount = totalParticipantCount + presentation.number_of_participants
          const indexInParticipantArray = participantCountPerProject.findIndex((item) => item.id.toString() === presentation.project.id.toString())

          if (indexInParticipantArray !== -1) {
            const itemToUpdate = participantCountPerProject[indexInParticipantArray]
            participantCountPerProject.splice(indexInParticipantArray, 1, {
              ...itemToUpdate,
              participantCount: itemToUpdate.participantCount + presentation.number_of_participants,
              durationsArray: [...itemToUpdate.durationsArray, duration],
            })
          } else {
            const newItem = {
              id: presentation.project.id.toString(),
              participantCount: presentation.number_of_participants,
              durationsArray: [duration],
            }
            participantCountPerProject.push(newItem)
          }
        })
      }

      /* SLOTS */
      const slotsPerAppointment = appointment.order_items.length
      if (isTrainingCompleted) {
        totalSlots = totalSlots + slotsPerAppointment
        completedSlots = completedSlots + slotsPerAppointment
        bookedOrCompltedAppointments++
      } else if (isTrainingBooked) {
        totalSlots = totalSlots + slotsPerAppointment
        bookedSlots = bookedSlots + slotsPerAppointment
        bookedOrCompltedAppointments++
      }

      /* DATA FOR GRAPH */
      const indexOfItemInDataForGraphArray = dataForGraph.findIndex(({ label }) => label === format(appointmentDate, 'dd.MM.yyyy'))
      const itemToBeUpdatedInGraph = dataForGraph[indexOfItemInDataForGraphArray]
      if (isTrainingCompleted) {
        dataForGraph.splice(indexOfItemInDataForGraphArray, 1, {
          ...itemToBeUpdatedInGraph,
          trainingDoneCount: itemToBeUpdatedInGraph.trainingDoneCount + 1,
        })
      }
      if (isCancelled && !isVisit) {
        dataForGraph.splice(indexOfItemInDataForGraphArray, 1, {
          ...itemToBeUpdatedInGraph,
          cancelledCount: itemToBeUpdatedInGraph.cancelledCount + 1,
        })
      }
      if (isTrainingBooked && isFutureAppointment) {
        dataForGraph.splice(indexOfItemInDataForGraphArray, 1, {
          ...itemToBeUpdatedInGraph,
          bookedCount: itemToBeUpdatedInGraph.bookedCount + 1,
        })
      }
      if (isVisit) {
        dataForGraph.splice(indexOfItemInDataForGraphArray, 1, {
          ...itemToBeUpdatedInGraph,
          visitCount: itemToBeUpdatedInGraph.visitCount + 1,
        })
      }
    })

    /* TRAINING SETS */
    const finishedPresentations = finishedPresentationsFromQuery?.filter((item) => !item.project?.name || !item.project?.name.startsWith('ApoCampus'))
    const finishedPresentationsCount = finishedPresentations?.length
    const repeatedTrainigs = finishedPresentations?.filter((presentation) => presentation.erstschulung === false)
    const repeatedTrainigsCount = repeatedTrainigs?.length ?? 0

    /* PARTICIPANTS */
    const sumOfParticipants = finishedPresentations?.reduce((total, training) => total + training.adjusted_number_of_participants, 0)

    /* SLOTS */
    const finishedSlots = finishedPresentations?.filter((presentation) => presentation.erstschulung === true)
    const completedSlotsCount = finishedSlots?.length
    const totalSlotsCount = (completedSlotsCount ?? 0) + (bookedSlots ?? 0)

    /* PHARMACIES */
    const pharmaciesInTrainingCategories = trainedPharmaciesArray.concat(bookedForFuturePharmaciesArray, pharmaciesWithCancelledAppointmentsArray)
    const uniquePharmaciesInTrainingCategories = new Set(pharmaciesInTrainingCategories)
    const pharmaciesWithMultipleAppointmentsCount = pharmaciesInTrainingCategories.length - uniquePharmaciesInTrainingCategories.size
    const pharmaciesAnsweredQuestion = pharmacies_answered_question ?? 0
    const trainedPharmaciesCount = new Set(trainedPharmaciesArray).size
    const trainingsQuote =
      trainedPharmaciesCount + pastCancelledTrainings > 0 ? (trainedPharmaciesCount / (trainedPharmaciesCount + pastCancelledTrainings)) * 100 : 0
    return {
      appointmentsCompleted,
      visitsCompleted,
      appointmentsBooked,
      visitsBooked,
      totalAppointmentsCount,
      bookedPharmaciesCount: new Set(bookedPharmaciesArray).size,
      trainedPharmaciesCount,
      bookedForFuturePharmaciesCount: new Set(bookedForFuturePharmaciesArray).size,
      pharmaciesWithCancelledAppointmentsCount: new Set(pharmaciesWithCancelledAppointmentsArray).size,
      pharmaciesAnsweredQuestion,
      pharmaciesWithMultipleAppointmentsCount,
      trainingsQuote,
      pastTrainingSets: finishedPresentationsCount ?? 0,
      repeatedTrainigsCount: repeatedTrainigsCount,
      pastRepeatedTrainingSets,
      totalSlots: totalSlotsCount,
      completedSlots: completedSlotsCount ?? 0,
      bookedSlots,
      bookedOrCompltedAppointments,
      totalParticipantCount: sumOfParticipants ?? 0,
      totalCompletedTrainingAppointmentCount,
      appointmentSummaryPerProject,
      participantCountPerProject,
      dataForGraph,
      appointmentsCreatedByAgent,
    }
  }, [
    clusterAgentId,
    appointmentsFromQuery,
    cancelledStatusIds,
    trainingFinishedStatusId,
    trainingBookedStatusId,
    graphLabels,
    finishedPresentationsFromQuery,
  ])

  const averageAppointmentCount = getAverageValues(summaryData.totalAppointmentsCount, totalDaysInField)
  const averageTrainingsPerDay = getAverageValues(summaryData.pastTrainingSets, daysInFieldUntilToday)
  const averageSlotsPerAppointment = getAverageValues(summaryData.completedSlots + summaryData.bookedSlots, summaryData.bookedOrCompltedAppointments)
  const averageSlotsPerDay = getAverageValues(summaryData.completedSlots + summaryData.bookedSlots, totalDaysInField)
  const averageParticipantsPerAppointment = getAverageValues(summaryData.totalParticipantCount, summaryData.pastTrainingSets)
  const exitQuestionResponseRate =
    summaryData.trainedPharmaciesCount > 0 ? (summaryData.pharmaciesAnsweredQuestion / summaryData.trainedPharmaciesCount) * 100 : 0

  const summaryPerProjectData = useMemo(() => {
    let pharmacyProjectArray: Pick<SummaryPerProjectDataType, 'id' | 'projectPharmacyCount'>[] = []
    pharmacies?.forEach((pharmacy) => {
      pharmacy.projects.forEach((project) => {
        if (project.pivot.status_id) {
          return
        }
        const indexInProjectList = pharmacyProjectArray.findIndex((item) => item.id.toString() === project.id.toString())
        if (indexInProjectList === -1) {
          const projectCountItem = { projectPharmacyCount: 1, id: project.id.toString() }
          pharmacyProjectArray.push(projectCountItem)
        } else {
          const itemToUpdate = pharmacyProjectArray[indexInProjectList]
          const newItem = { ...itemToUpdate, projectPharmacyCount: itemToUpdate.projectPharmacyCount + 1 }
          pharmacyProjectArray.splice(indexInProjectList, 1, newItem)
        }
      })
    })

    return activeProjects.map((project) => {
      const now = new Date()
      const trainingsForProject = finishedPresentationsFromQuery?.filter((presentation) => presentation.project.name === project.name)
      const completedTrainigs = trainingsForProject?.length
      const timeSpentValues =
        finishedPresentationsFromQuery
          ?.filter((presentation) => presentation.project.name === project.name && typeof presentation.time_spent === 'string')
          .map((item) => parseInt(item.time_spent!, 10)) ?? []
      const repeatedProjectTrainigsCount = finishedPresentationsFromQuery?.filter(
        (presentation) => presentation.project.name === project.name && presentation.erstschulung === false,
      ).length
      const pharmaciesReceivedTrainingCount = finishedPresentationsFromQuery?.filter(
        (presentation) => presentation.project.name === project.name && presentation.erstschulung === true,
      ).length
      const bookedTrainigsFromTomorrowCount = appointmentsFromQuery?.filter((appointment) => {
        const projectIds = appointment.order_items.map((item) => item.project_id.toString())
        return (
          appointment.date &&
          projectIds.includes(project.id.toString()) &&
          isAfter(parseISO(appointment.date), now) &&
          appointment.appointmentType?.label === 'Schulung'
        )
      }).length
      const itemFromTheAppointmentSummary = summaryData.appointmentSummaryPerProject.find(({ id }) => id.toString() === project.id.toString())
      const goalPerDateRange = summarizedGoals[project.id] || 0
      const totalTrainingCount = (pharmaciesReceivedTrainingCount ?? 0) + (bookedTrainigsFromTomorrowCount ?? 0)
      const gapTrainingCount = +goalPerDateRange - totalTrainingCount
      const bookedTimePerProject = (project.booked_time_in_seconds / 60).toFixed()
      const sumOfParticipants = trainingsForProject?.reduce((total, training) => total + (training.adjusted_number_of_participants ?? 0), 0)
      const projectIdToFilter = project.id
      const pharmaciesForProject = pharmacies?.filter((pharmacy) => {
        const hasProject = pharmacy.projects.some((project) => project.id === projectIdToFilter)
        //  commented check for existing appointments and finished presentation
        // const hasNoTrainingAppointments = pharmacy.appointments
        //   .filter((appointment) => appointment.order_items.length > 0)
        //   .every((appointment) => {
        //     return appointment?.appointmentType?.id !== '1' || !appointment.order_items.some((item) => item.project_id === +projectIdToFilter)
        //   })

        return hasProject
        //&&
        // hasNoTrainingAppointments &&
        // (trainingsForProject ? trainingsForProject.every((training) => training.pharmacy.id !== pharmacy.id) : true)
      }).length
      return {
        id: project.id.toString(),
        name: project.name,
        trainingCompletedCount: completedTrainigs ?? 0,
        projectPharmacyCount: pharmaciesForProject ?? 0,
        pharmaciesReceivedTrainingCount: pharmaciesReceivedTrainingCount ?? 0,
        bookedFromTomorrow: bookedTrainigsFromTomorrowCount ?? 0,
        cancelledCount: itemFromTheAppointmentSummary?.cancelledCount ?? 0,
        consecutiveTrainingCount: repeatedProjectTrainigsCount ?? 0,
        participantCount: sumOfParticipants ?? 0,
        averageDuration: timeSpentValues.length > 0 ? timeSpentValues.reduce((a, b) => a + b, 0) / timeSpentValues.length : 0,
        projectGoal: +goalPerDateRange,
        totalTrainingCount: totalTrainingCount,
        gapTrainingCount: gapTrainingCount,
        averageTotalTrainingCount: totalDaysInField > 0 ? (totalTrainingCount / totalDaysInField).toFixed(2) : 0,
        averageTrainingGoal: totalDaysInField > 0 ? (+goalPerDateRange / totalDaysInField).toFixed(2) : 0,
        averageGapTrainingCount: totalDaysInField > 0 ? (gapTrainingCount / totalDaysInField).toFixed(2) : 0,
        goalAchievement: +goalPerDateRange > 0 ? ((totalTrainingCount / +goalPerDateRange) * 100).toFixed() : 0,
        bookedTimePerProject,
      }
    })
  }, [
    activeProjects,
    summaryData.appointmentSummaryPerProject,
    pharmacies,
    summarizedGoals,
    summaryData.participantCountPerProject,
    dates,
    appointmentsFromQuery,
    finishedPresentationsFromQuery,
  ])

  return {
    [DashboardStructureType.SUMMARY_CARDS]: {
      appointmentsCompleted: summaryData.appointmentsCompleted,
      appointmentsCreatedByAgent: summaryData.appointmentsCreatedByAgent,
      appointmentsBooked: summaryData.appointmentsBooked,
      visitsCompleted: summaryData.visitsCompleted,
      visitsBooked: summaryData.visitsBooked,
      averageAppointmentCount,
      bookedPharmaciesCount: summaryData.bookedPharmaciesCount,
      pharmaciesCount: pharmacies?.length ?? 0,
      trainedPharmaciesCount: summaryData.trainedPharmaciesCount,
      bookedForFuturePharmaciesCount: summaryData.bookedForFuturePharmaciesCount,
      pharmaciesWithMultipleAppointmentsCount: summaryData.bookedForFuturePharmaciesCount,
      pharmaciesWithCancelledAppointmentsCount: summaryData.pharmaciesWithCancelledAppointmentsCount,
      repeatedTrainigsCount: summaryData.repeatedTrainigsCount,
      trainingsQuote: summaryData.trainingsQuote,
      pastTrainingSets: summaryData.pastTrainingSets,
      pastRepeatedTrainingSets: summaryData.pastRepeatedTrainingSets,
      averageTrainingsPerDay,
      totalSlots: summaryData.totalSlots,
      bookedSlots: summaryData.bookedSlots,
      completedSlots: summaryData.completedSlots,
      averageSlotsPerAppointment,
      averageSlotsPerDay,
      totalParticipantCount: summaryData.totalParticipantCount,
      averageParticipantsPerAppointment,
      homeOfficeCount,
      vacationCount,
      privateCount,
      meetingsCount,
      sickCount,
      vacantCount,
      daysInFieldLeft,
      totalWorkingDays,
      totalDaysInField,
      exitQuestionResponseRate,
    },
    isLoading,
    [DashboardStructureType.GRAPH]: summaryData.dataForGraph,
    [DashboardStructureType.SUMMARY_PER_PROJECT]: summaryPerProjectData,
  }
}
