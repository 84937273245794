import React from 'react'
import { SummaryPerProjectDataType } from '../../../contexts/dashboardContext'
import { InfoContainer } from '../InfoContainer'

const getTextColorClass = (goalAchievement: string | number | undefined) => {
  if ((goalAchievement && +goalAchievement < 80) || goalAchievement == 0) {
    return 'text-red-700 font-bold' // Red color
  } else if (goalAchievement && +goalAchievement <= 99) {
    return 'text-yellow-600 font-bold' // Yellow color
  } else {
    return 'text-green-450-hi font-bold' // Green color
  }
}

export const OverviewPerProject = ({ summaryPerProjectData }: { summaryPerProjectData: SummaryPerProjectDataType[] }) => {
  const infoContainerData = summaryPerProjectData.map((project) => ({
    projectId: Number(project.id),
    title: project.name,
    data: [
      { label: 'Anzahl der Projektapotheken', value: project.projectPharmacyCount ?? 0 },
      { label: 'präsentiert', value: project.trainingCompletedCount },
      { label: 'Apotheken (durchgeführt)', value: project.pharmaciesReceivedTrainingCount },
      { label: 'Teilnehmer', value: project.participantCount },
      {
        label: 'Ø Teilnehmer',
        value: project.trainingCompletedCount ? (project.participantCount / project.trainingCompletedCount).toFixed() : '-',
      },
      { label: `Ø (${project.bookedTimePerProject}min)`, value: (project.averageDuration / 60 ?? 0).toFixed() },
      { label: 'avisiert', value: project.bookedFromTomorrow },
      { label: 'Mehrfachdurchgänge', value: project.consecutiveTrainingCount },
      { label: 'abgesagte Termine', value: project.cancelledCount },
      { label: 'ist gesamt', value: project.totalTrainingCount },
      { label: 'soll gesamt', value: project.projectGoal },
      { label: 'gap gesamt', value: project.gapTrainingCount },
      { label: 'Ø soll', value: project.averageTrainingGoal ?? 0 },
      { label: 'Ø ist', value: project.averageTotalTrainingCount ?? 0 },
      { label: 'Ø gap', value: project.averageGapTrainingCount ?? 0 },
      { label: 'Zielerreichung', value: `${project.goalAchievement}%`, textColorClass: getTextColorClass(project.goalAchievement) },
    ],
  }))

  return (
    <div className="flex flex-wrap">
      {infoContainerData.map((props) => (
        <InfoContainer key={props.title} withGoals {...props} />
      ))}
    </div>
  )
}
