import React, { useState, useMemo } from 'react'
import { Pharmacy } from '@aposphaere/core-kit'
import { SectionCard } from '@aposphaere/ui-components'
import { formattedTimeString, parseUTC, formattedDateString } from '@aposphaere/core-kit'

export interface ActivityLogCardProps {
  pharmacy: Pharmacy | null
}

export interface PharmacyActivitiesLog {
  username: string
  model: string
  timestamp: string
  identificator: number
}

export enum ActivityEvents {
  created = 'erstellung',
  deleted = 'löschung',
  updated = 'aktualisierung',
}

export enum ActivityModels {
  Appointment = 'Termin',
  Task = 'Wiedervorlage',
  Note = 'Notiz',
  Pharmacy = 'Apotheke',
}

const ActivityLogCard: React.FC<ActivityLogCardProps> = ({ pharmacy }) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  const getActivityName = (model: string, event: string) => {
    const activityModel = model.split('\\')[2] as keyof typeof ActivityModels
    const activityEvent = ActivityEvents[event as keyof typeof ActivityEvents]
    if (activityModel in ActivityModels && activityEvent) {
      return `${ActivityModels[activityModel]}${activityEvent}`
    } else {
      return ''
    }
  }

  const getActivities = (pharmacy: Pharmacy | null): PharmacyActivitiesLog[] => {
    return (
      pharmacy?.activity_log?.map((activity) => {
        const activityName = getActivityName(activity.model, activity.event)
        return {
          username: activity.user_name,
          model: activityName,
          timestamp: activity.timestamp,
          identificator: Math.floor(Math.random() * 10001),
        }
      }) || []
    )
  }

  const pharmacyActivities: PharmacyActivitiesLog[] = useMemo(() => getActivities(pharmacy).reverse(), [pharmacy])

  return (
    <SectionCard
      isExpanded={expanded}
      setExpanded={setExpanded}
      badgeValue={pharmacyActivities.length}
      title="Aktivitätslog"
      showAddButton={false}
      showSpecialButton={false}
      showAllEntries={false}
    >
      <div className="px-2 2xl:px-4 ">
        {pharmacyActivities.length === 0 ? (
          <p className="w-full py-2 text-base text-gray-600 text-center">{'Keine Einträge vorhanden'}</p>
        ) : (
          <li className="w-full px-2 2xl:px-4 border-b border-gray-400 last:border-0">
            {pharmacyActivities?.map(({ username, model, timestamp, identificator }) => (
              <div className="grid grid-cols-7 xl:grid-cols-6 gap-2 w-full py-2" key={`${username}_${model}_${timestamp}_${identificator}`}>
                <div className="flex col-span-2 min-w-130px">
                  <div className="text-base leading-6 font-medium whitespace-normal">
                    {/* Date  */}
                    <span className="font-medium">{timestamp ? formattedDateString(parseUTC(timestamp)) : ''}</span>
                    <br />
                    {/* Time  */}
                    <span className="font-medium ">{timestamp ? formattedTimeString(parseUTC(timestamp)) : ''}</span>
                  </div>
                </div>
                <div className="col-span-1 xl:hidden" />
                <div className={`flex col-span-3 items-center ml-16`}>
                  <div className="text-base leading-6 font-medium">
                    <span className="font-normal">{model}</span>
                    <br />
                    <div className="font-medium">
                      <p className="">{username}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </li>
        )}
      </div>
    </SectionCard>
  )
}

export default ActivityLogCard
