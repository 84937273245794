import { OpeningHour } from '../components/PharmacyDataSheetPanel/PharmacyDataSheetTypes'

const readableWeekday = (day: number) => {
  switch (day) {
    case 0:
      return 'So:'
    case 1:
      return 'Mo:'
    case 2:
      return 'Di:'
    case 3:
      return 'Mi:'
    case 4:
      return 'Do:'
    case 5:
      return 'Fr:'
    case 6:
      return 'So:'
    default:
      return ''
  }
}

const formatOpeningHours = (slots: string[][]) => slots.map((slot) => slot.join(' - ')).join(', ')

export const getOpeningHours = (openingTimes?: string) => {
  if (!openingTimes) {
    return [['Keine Öffnungszeiten hinterlegt']]
  }

  const openingHours: OpeningHour[] = JSON.parse(openingTimes!) as OpeningHour[]

  return openingHours.map(({ weekday, slots }) => [readableWeekday(weekday), formatOpeningHours(slots)])
}
