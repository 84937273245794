import { parseUTC } from '@aposphaere/core-kit'
import { differenceInMinutes } from 'date-fns'
import React, { useMemo } from 'react'
import { ModifiedAppointmentType } from './InfoContainerTabs'
import { useCrmContext } from '../../contexts/crmContext'

type SlotsDataType = { deviceCount: number; amount: number; project: string; duration: number }

export const SlotsOverview = ({ appointments }: { appointments: ModifiedAppointmentType[] }) => {
  const { isUserCallcenter } = useCrmContext()

  const sortedAppointments = appointments.slice()
  sortedAppointments.sort((a, b) => {
    const dateA = a.date ? parseUTC(a.date) : new Date(0)
    const dateB = b.date ? parseUTC(b.date) : new Date(0)
    return dateB.getTime() - dateA.getTime()
  })

  const slotsData = useMemo(
    () =>
      sortedAppointments
        .flatMap((appointment) => appointment.finished_presentations)
        .reduce((result: SlotsDataType[], presentation) => {
          const existingEntry = result.find((entry) => entry.project === presentation?.project.name)
          const deviceCountCurrent = presentation?.device_count ?? 0
          const durationCurrent = presentation?.time_spent ? parseInt(presentation.time_spent, 10) / 60 : 0
          const roundedLengthOfTraining = Math.round(durationCurrent)

          if (existingEntry) {
            existingEntry.deviceCount += deviceCountCurrent
            existingEntry.amount += 1
            const duration = roundedLengthOfTraining
            existingEntry.duration += duration
          } else {
            result.push({
              deviceCount: deviceCountCurrent,
              amount: 1,
              project: presentation?.project?.name || '',
              duration: roundedLengthOfTraining,
            })
          }

          return result
        }, [])
        .map((entry) => ({
          deviceCount: entry.deviceCount,
          amount: entry.amount,
          project: entry.project,
          duration: entry.duration / entry.amount,
        })),
    [appointments],
  )

  return (
    <>
      {!slotsData.length && <div>Keine Einträge vorhanden</div>}
      {isUserCallcenter && slotsData.length ? (
        <div>{`${slotsData[slotsData.length - 1].amount}x ${slotsData[slotsData.length - 1].project} - ${
          slotsData[slotsData.length - 1].deviceCount
        } iPad(s) - ${slotsData[slotsData.length - 1].duration}min`}</div>
      ) : (
        slotsData.map(({ deviceCount, amount, project, duration }, idx) => (
          <div key={idx}>{`${amount}x ${project} - ${deviceCount} iPad(s) - ${duration}min`}</div>
        ))
      )}
    </>
  )
}
