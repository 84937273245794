import { TimeSlot, TimeSlotKind } from '@aposphaere/ui-components'
import React, { useEffect, useRef } from 'react'
import { CalendarTimeSlot } from './useCalendarData'
import { useCalendarPanel } from '../CalendarPanelAppointment/CalendarPanelContext'
import { useAppointmentsQuery } from '../../hooks/graphql'
import { formattedDateString, parseUTC } from '@aposphaere/core-kit'
import { useRoutePlanner } from '../../contexts/routePlannerContext'
import { SidebarStatus } from '../../pages/StartPage/StartPage'

const formatAppointmentDate = (date: Date) => `${padDatePart(date.getHours())}:${padDatePart(date.getMinutes())}`

const padDatePart = (h: number) => (h < 10 ? `0${h}` : h)

interface TimeSlotsProps {
  day: Date
  timeSlots: CalendarTimeSlot[]
  onClick: (slot: CalendarTimeSlot) => void
  setRouteForAppointment?: React.Dispatch<React.SetStateAction<boolean>>
  setShouldUpdateDataItem?: React.Dispatch<React.SetStateAction<boolean>>
  sidebarStatus?: SidebarStatus
}

const TimeSlots = ({ timeSlots, onClick, setRouteForAppointment, setShouldUpdateDataItem, sidebarStatus }: TimeSlotsProps) => {
  const slotRef = useRef<CalendarTimeSlot | undefined>(undefined)
  const { data: appointments } = useAppointmentsQuery()
  const { selectedTime } = useCalendarPanel()
  const { setSelectedDay, setShowSelectedDayOverview, setRouteResponse } = useRoutePlanner()

  useEffect(() => {
    if (slotRef.current) {
      handleDaySelection(slotRef.current)
    }
  }, [selectedTime])

  const handleDaySelection = (slot: CalendarTimeSlot) => {
    if (sidebarStatus?.type === 'appointment-creation') {
      const filteredAppointments = appointments?.filter(
        (el) =>
          formattedDateString(el.date ? parseUTC(el.date) : new Date()) === formattedDateString(selectedTime) && el.pharmacy?.id && !el.is_deleted,
      )
      setShowSelectedDayOverview(true)
    }
  }

  return (
    <React.Fragment>
      {timeSlots.map((slot) => (
        <TimeSlot
          onClick={(e) => {
            e.stopPropagation() // To avoid triggering the click handler on the whole day
            onClick(slot)
            setRouteResponse(null)
            if (setRouteForAppointment && slot.type !== 'appointment') {
              setRouteForAppointment(true)
            }
            slotRef.current = slot
            handleDaySelection(slot)
            if (sidebarStatus?.type === 'appointment-creation') {
              setSelectedDay(slot.date)
            }
            if (setShouldUpdateDataItem) {
              setShouldUpdateDataItem(true)
            }
          }}
          key={getTimeslotKey(slot)}
          disabled={slot.kind === TimeSlotKind.disabled}
          kind={slot.kind}
          date={slot.date}
        >
          {slot.type === 'appointment' ? formatAppointmentDate(slot.date) : undefined}
        </TimeSlot>
      ))}
    </React.Fragment>
  )
}

function getTimeslotKey(slot: CalendarTimeSlot) {
  switch (slot.type) {
    case 'appointment':
    case 'offtime': {
      return `${slot.type}-${slot.data.id}-${slot.date?.toString() || ''}`
    }
    default: {
      return `${slot.type}-${slot.date?.toString() || ''}`
    }
  }
}

export default React.memo(TimeSlots)
