import { useAbilities } from '@aposphaere/core-kit'
import { EditIcon } from '@aposphaere/ui-components'
import { format, isFuture, isSameDay } from 'date-fns'
import React, { useMemo } from 'react'
import { REMOTE_APPOINTMENT_TYPE_FRAGMENT } from '../../constants'
import { useModal, ModalKind } from '../../contexts/modalContext'

import { ModifiedAppointmentType } from './InfoContainerTabs'

type AppointmentType = Omit<ModifiedAppointmentType, 'date'> & { info: string; date: string }

const ContentTemplate = ({ appointments, title }: { appointments: AppointmentType[]; title: string }) => {
  const abilities = useAbilities()
  const { openModal } = useModal()

  if (!appointments.length) {
    return null
  }

  return (
    <>
      <div className="mb-2 font-medium">{title}</div>
      {appointments.map(({ date, info, id }) => (
        <div className="flex justify-items-center" key={id}>
          <span className="font-medium pr-3">{date}</span>
          <span>{info}</span>
          <span>
            {abilities.edit_appointments && (
              <span>
                {abilities.edit_appointments && (
                  <button className="-mt-1" onClick={() => openModal({ kind: ModalKind.AppointmentEdit, id })}>
                    <EditIcon dimension="25" />
                  </button>
                )}
              </span>
            )}
          </span>
        </div>
      ))}
    </>
  )
}

export const AppointmentsInfo = ({ appointments }: { appointments: ModifiedAppointmentType[] }) => {
  const { futureVisits, futureAppointments } = useMemo(() => {
    const futureVisits: AppointmentType[] = []
    const futureAppointments: AppointmentType[] = []

    appointments?.forEach((appointment) => {
      const relevantAppointment = appointment.date && (isFuture(appointment.date) || isSameDay(appointment.date, new Date()))
      if (!relevantAppointment) {
        return
      }

      const infoProjects = appointment.order_items
        .filter((item) => {
          const name = item.project?.name
          return !name || !name.startsWith('ApoCampus')
        })
        .map((item) => {
          const isRemote = appointment.appointmentType?.label?.toLowerCase() === REMOTE_APPOINTMENT_TYPE_FRAGMENT
          let name = item.project?.name
          if (isRemote) {
            return `${name}-Remote`
          }
          return name
        })
        .join(', ')

      const isCancelled = appointment.isCancelled
      const isCompleted = appointment.status?.id === '3'

      let appointmentInfoResult

      if (isCancelled) {
        appointmentInfoResult = `Abgesagt${infoProjects ? ` (${infoProjects})` : ''}`
      } else if (isCompleted) {
        appointmentInfoResult = `Durchgeführt${infoProjects ? ` (${infoProjects})` : ''}`
      } else {
        appointmentInfoResult = `Avisiert${infoProjects ? ` (${infoProjects})` : ''}`
      }

      const newAppointment = {
        ...appointment,
        date: format(appointment.date!, 'dd.MM.yyyy HH:mm'),
        info: appointmentInfoResult,
      }

      if (appointment.isVisit) {
        futureVisits.push(newAppointment)
      } else {
        futureAppointments.push(newAppointment)
      }
    })

    return { futureVisits, futureAppointments }
  }, [appointments])

  return (
    <>
      {!futureVisits.length && !futureAppointments.length && <span>Keine Termine</span>}
      <ContentTemplate title="Nächste Termine:" appointments={futureAppointments} />
      <ContentTemplate title="Besuche:" appointments={futureVisits} />
    </>
  )
}
