import { useQuery } from 'react-query'
import { wholeGermanySummary, WholeGermanySummaryType, useGqlClient } from '@aposphaere/core-kit'
import { QueryOptions } from './utils'

type WholeGermanySummaryQueryProps = {
  options?: QueryOptions<WholeGermanySummaryType>
  date: { from: string; to: string }
  enabled: boolean
}

export const useGermanySummaryQuery = (props: WholeGermanySummaryQueryProps) => {
  const gqlClient = useGqlClient()

  return useQuery(
    ['wholeGermanySummary', `date-${JSON.stringify(props.date)}`],
    async () => {
      const data = await gqlClient.request<{ getSummary: WholeGermanySummaryType }>(wholeGermanySummary, { date: props.date })
      return data?.getSummary
    },
    { enabled: !!props.date && props.enabled, staleTime: Infinity, ...props?.options },
  )
}
