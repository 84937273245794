import { formattedDateString } from '@aposphaere/core-kit'
import React from 'react'
import { DayPickerProps } from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'

export type IDatePickerProps = {
  value: Date
  dayPickerProps: DayPickerProps
}

/* German localisation  */
const MONTHS = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
const WEEKDAYS_LONG = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']
const WEEKDAYS_SHORT = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']

export const DatePicker = ({ dayPickerProps, value }: IDatePickerProps) => {
  return (
    <DayPickerInput
      formatDate={formattedDateString}
      value={value}
      dayPickerProps={{
        firstDayOfWeek: 1,
        months: MONTHS,
        weekdaysLong: WEEKDAYS_LONG,
        weekdaysShort: WEEKDAYS_SHORT,
        locale: 'de',
        ...dayPickerProps,
      }}
      inputProps={{
        readOnly: true,
        className:
          'font-body w-full h-10 bg-gray-100 rounded-md px-4 py-2 text-base leading-6 border outline-none focus:shadow-focus border-solid border-gray-400 focus:border-blue-400 sm:leading-5',
      }}
    />
  )
}
