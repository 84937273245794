import React, { useCallback } from 'react'
import { subDays, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfQuarter, endOfQuarter, startOfYear, endOfYear, getYear } from 'date-fns'
import { DateType } from '../../../contexts/dashboardContext'

enum IDateRanges {
  TODAY = 'Heute',
  LAST_7_DAYS = 'Letzte 7 Tage',
  CURRENT_WEEK = 'Diese Woche',
  CURRENT_MONTH = 'Aktueller Monat',
  CURRENT_QUARTER = 'Laufendes Quartal',
  CURRENT_YEAR = 'Laufendes Jahr',
  FIRST_QUARTER = 'FIRST_QUARTER',
  SECOND_QUARTER = 'SECOND_QUARTER',
  THIRD_QUARTER = 'THIRD_QUARTER',
  LAST_QUARTER = 'LAST_QUARTER',
}

export const DateRangeSelector = ({ setDates }: { setDates: React.Dispatch<React.SetStateAction<DateType>> }) => {
  const today = new Date()
  const currentYear = getYear(today)

  const getRangeLabel = useCallback(
    (range: IDateRanges) => {
      switch (range) {
        case IDateRanges.FIRST_QUARTER: {
          return `Q1/${currentYear}`
        }
        case IDateRanges.SECOND_QUARTER: {
          return `Q2/${currentYear}`
        }
        case IDateRanges.THIRD_QUARTER: {
          return `Q3/${currentYear}`
        }
        case IDateRanges.LAST_QUARTER: {
          return `Q4/${currentYear}`
        }
        default:
          return range
      }
    },
    [currentYear],
  )
  const dateRangeValues = Object.values(IDateRanges).map((value) => ({ value, label: getRangeLabel(value) }))

  const predifinedDateRanges = dateRangeValues.slice(0, 6)
  const quarterDateRanges = dateRangeValues.slice(6)

  const handleDateRangeChange = (range: IDateRanges) => {
    switch (range) {
      case IDateRanges.TODAY: {
        const newDates = { startDate: today, endDate: today }
        setDates(newDates)
        return
      }
      case IDateRanges.LAST_7_DAYS: {
        const newDates = { startDate: subDays(today, 6), endDate: today }
        setDates(newDates)
        return
      }
      case IDateRanges.CURRENT_WEEK: {
        const newDates = { startDate: startOfWeek(today, { weekStartsOn: 1 }), endDate: endOfWeek(today, { weekStartsOn: 1 }) }
        setDates(newDates)
        return
      }
      case IDateRanges.CURRENT_MONTH: {
        const newDates = { startDate: startOfMonth(today), endDate: endOfMonth(today) }
        setDates(newDates)
        return
      }
      case IDateRanges.CURRENT_QUARTER: {
        const newDates = { startDate: startOfQuarter(today), endDate: endOfQuarter(today) }
        setDates(newDates)
        return
      }
      case IDateRanges.CURRENT_YEAR: {
        const newDates = { startDate: startOfYear(today), endDate: endOfYear(today) }
        setDates(newDates)
        return
      }
      case IDateRanges.FIRST_QUARTER: {
        const newDates = { startDate: new Date(`${currentYear}-01-01`), endDate: new Date(`${currentYear}-03-31`) }
        setDates(newDates)
        return
      }
      case IDateRanges.SECOND_QUARTER: {
        const newDates = { startDate: new Date(`${currentYear}-04-01`), endDate: new Date(`${currentYear}-06-30`) }
        setDates(newDates)
        return
      }
      case IDateRanges.THIRD_QUARTER: {
        const newDates = { startDate: new Date(`${currentYear}-07-01`), endDate: new Date(`${currentYear}-09-30`) }
        setDates(newDates)
        return
      }
      case IDateRanges.LAST_QUARTER: {
        const newDates = { startDate: new Date(`${currentYear}-10-01`), endDate: new Date(`${currentYear}-12-31`) }
        setDates(newDates)
        return
      }
    }
  }

  return (
    <select
      onChange={(event: React.FormEvent<HTMLSelectElement>) => handleDateRangeChange(event.currentTarget.value as IDateRanges)}
      defaultValue={IDateRanges.CURRENT_QUARTER}
      className="w-1/5 form-select h-10 text-gray-900 bg-gray-100 rounded-md pr-10 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400 mr-3"
    >
      <optgroup label="Special">
        {predifinedDateRanges.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </optgroup>
      <optgroup label="Quartal">
        {quarterDateRanges.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </optgroup>
    </select>
  )
}
