import { AppointmentStatuses, Appointment } from '@aposphaere/core-kit'
import { parseUTC } from '@aposphaere/core-kit'
import { format } from 'date-fns'
import { FinishedPresentation, PharmaciesForDownload } from '../../hooks/useDataDashboardsDownload'

export const generateCSVDataCRMAppointments = (appointmentsForDownload: Appointment[]): string => {
  const header = [
    'adressid',
    'Datum',
    'Uhrzeit',
    'TerminTyp',
    'Status',
    'Firmenname',
    'Strasse',
    'PLZ',
    'Ort',
    'Kontaktperson',
    'Terminnotitz',
    'Referent',
  ]

  const rows = appointmentsForDownload.map((appointment) => {
    const formattedDate = appointment.date ? format(parseUTC(appointment.date), 'dd.MM.yyyy HH:mm') : ''
    const dateParts = formattedDate.split(' ')

    return [
      appointment.id,
      dateParts.length > 1 ? dateParts[0] : '',
      dateParts.length > 1 ? dateParts[1] : '',
      appointment.appointmentType?.label,
      (AppointmentStatuses[appointment.status?.id as keyof typeof AppointmentStatuses] || '') as AppointmentStatuses,
      appointment.pharmacy?.name,
      appointment.pharmacy?.address.address_name,
      appointment.pharmacy?.address.zipcode,
      appointment.pharmacy?.address.city,
      appointment.contact_person,
      appointment?.note
        ?.replace(/(?:\r\n|\r|\n)/g, ' ')
        .replace(';', ',')
        .replace('/', ''),
      appointment.creator?.name,
    ]
  })

  const csvContent = [header, ...rows].map((row) => row.join(';')).join('\n')
  return csvContent
}

export const generateCSVDataPharmacies = (pharmacyForDownload: PharmaciesForDownload[]): string => {
  const header = [
    'id',
    'Firmenname',
    'Strasse',
    'PLZ',
    'Ort',
    'Klassifikation',
    'Referent',
    'GeschulteSlots',
    'Termine',
    'Termincounter',
    'Produktcounter',
  ]

  const rows = Object.values(pharmacyForDownload).map((pharmacy) => {
    return [
      pharmacy.id,
      pharmacy.name,
      pharmacy.address,
      pharmacy.zipcode,
      pharmacy.city,
      pharmacy.classification,
      pharmacy.referent,
      `"${pharmacy.projects.join(',')}"`,
      `"${pharmacy.appointments.join(',')}"`,
      pharmacy.appointmentsCounter,
      pharmacy.productCounter,
    ]
  })

  const csvContent = [header, ...rows].map((row) => row.join(';')).join('\n')
  return csvContent
}

export const generateCSVDataTrainings = (finishedPresentationsForDownloadForDownload: FinishedPresentation[]): string => {
  const header = [
    'id',
    'Datum',
    'GeschulteSlots',
    'Firmenname',
    'Strasse',
    'PLZ',
    'Ort',
    'Kontaktperson',
    'Terminnotiz',
    'Referent',
    'IPadTeilnehmer',
    'EingegebeneTeilnehmer',
  ]

  const rows = Object.values(finishedPresentationsForDownloadForDownload).map((finishedPresentation) => {
    const formattedDate = finishedPresentation.starttime ? format(parseUTC(finishedPresentation.starttime), 'dd.MM.yyyy HH:mm') : ''
    const dateParts = formattedDate.split(' ')
    return [
      finishedPresentation.id,
      dateParts[0],
      finishedPresentation.project?.name,
      finishedPresentation.pharmacy?.name,
      finishedPresentation.pharmacy?.address.address_name,
      finishedPresentation.pharmacy?.address.zipcode,
      finishedPresentation.pharmacy?.address.city,
      finishedPresentation.appointment?.contact_person,
      finishedPresentation.appointment?.note
        ?.replace(/(?:\r\n|\r|\n)/g, ' ')
        .replace(';', ',')
        .replace('/', ''),
      finishedPresentation.trainer.name,
      finishedPresentation.device_count,
      finishedPresentation.number_of_participants,
    ]
  })

  const csvContent = [header, ...rows].map((row) => row.join(';')).join('\n')
  return csvContent
}
