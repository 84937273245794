import { Appointment, Pharmacy } from '@aposphaere/core-kit'
import { TabBar, TabBarItem, TabBarItemKind } from '@aposphaere/ui-components'
import React, { useMemo, useState } from 'react'
import { VISIT_APPOINTMENT_TYPE_FRAGMENT } from '../../constants'
import { useAppointmentsQuery, useCancelledStatusesIds, useCompletedStatusId } from '../../hooks/graphql'
import { AppointmentsInfo } from './AppointmentsInfo'
import { BranchesOverview } from './BranchesOverview'
import { HistoryInfo } from './HistoryInfo'
import { NotesOverview } from './NotesOverview'
import { OpeningHoursOverview } from './OpeningHoursOverview'
import { ProjectCancellation } from './ProjectCancellation'
import { SlotsOverview } from './SlotsOverview'
import { parse, format } from 'date-fns'

type TabsType = 'termine' | 'historie' | 'slots' | 'notizen' | 'kunden' | 'öffnung' | 'filialen'

export type ModifiedAppointmentType = Omit<Appointment, 'date'> & {
  date: Date | undefined
  isCancelled: boolean
  isVisit: boolean
  isCompleted: boolean
}

type Props = { activePharmacy: Pharmacy | undefined; setSelectedPharmacy: (id: number | undefined) => void }

export const InfoContainerTabs = ({ activePharmacy, setSelectedPharmacy }: Props) => {
  const [tabSelected, setTabSelected] = useState<TabsType>('termine')
  const { data: appointments } = useAppointmentsQuery()
  const cancelledStatusesIds = useCancelledStatusesIds()
  const completedStatusIds = useCompletedStatusId()
  const pharmacyId = activePharmacy?.id ?? ''
  const showBranchesTab = activePharmacy?.branches?.length || activePharmacy?.parent

  const pharmacyAppointments = useMemo(
    () =>
      appointments
        ?.filter((appointment) => appointment.pharmacy?.id === pharmacyId)
        ?.map((appointment) => {
          const parsedDate = appointment.date ? parse(appointment.date, 'yyyy-MM-dd HH:mm:ss', new Date()) : undefined
          let date
          if (parsedDate) {
            const formattedDate = format(parsedDate, "EEE MMM dd yyyy HH:mm:ss 'GMT'xxx", { useAdditionalWeekYearTokens: false })
            date = parse(formattedDate, "EEE MMM dd yyyy HH:mm:ss 'GMT'xxx", new Date())
          }
          const isCancelled = cancelledStatusesIds.includes(appointment?.status?.id || '')
          const isVisit = appointment?.appointmentType?.label?.toLowerCase() === VISIT_APPOINTMENT_TYPE_FRAGMENT
          const isCompleted = completedStatusIds.includes(appointment?.status?.id ?? '')
          return { ...appointment, date, isCancelled, isVisit, isCompleted }
        }) ?? [],
    [appointments, cancelledStatusesIds, completedStatusIds, pharmacyId],
  )

  return (
    <>
      <div className="mb-2">
        <TabBar>
          <TabBarItem kind={TabBarItemKind.secondary} selected={tabSelected === 'termine'} onClick={() => setTabSelected('termine')}>
            Termine
          </TabBarItem>
          <TabBarItem kind={TabBarItemKind.secondary} selected={tabSelected === 'historie'} onClick={() => setTabSelected('historie')}>
            Historie
          </TabBarItem>
          <TabBarItem kind={TabBarItemKind.secondary} selected={tabSelected === 'slots'} onClick={() => setTabSelected('slots')}>
            Slots
          </TabBarItem>
          <TabBarItem kind={TabBarItemKind.secondary} selected={tabSelected === 'notizen'} onClick={() => setTabSelected('notizen')}>
            Notizen
          </TabBarItem>
          <TabBarItem kind={TabBarItemKind.secondary} selected={tabSelected === 'kunden'} onClick={() => setTabSelected('kunden')}>
            Kunden
          </TabBarItem>
          <TabBarItem kind={TabBarItemKind.secondary} selected={tabSelected === 'öffnung'} onClick={() => setTabSelected('öffnung')}>
            Öffnung
          </TabBarItem>
          {showBranchesTab && (
            <TabBarItem kind={TabBarItemKind.secondary} selected={tabSelected === 'filialen'} onClick={() => setTabSelected('filialen')}>
              Filialen
            </TabBarItem>
          )}
        </TabBar>
      </div>
      <div className="ml-2">
        {tabSelected === 'termine' && <AppointmentsInfo appointments={pharmacyAppointments} />}
        {tabSelected === 'historie' && <HistoryInfo appointments={pharmacyAppointments} />}
        {tabSelected === 'slots' && <SlotsOverview appointments={pharmacyAppointments} />}
        {tabSelected === 'notizen' && <NotesOverview notes={activePharmacy?.notes ?? []} />}
        {tabSelected === 'kunden' && <ProjectCancellation pharmacyId={Number(pharmacyId)} />}
        {tabSelected === 'öffnung' && <OpeningHoursOverview openingTimes={activePharmacy?.opening_times} />}
        {tabSelected === 'filialen' && <BranchesOverview pharmacy={activePharmacy} setSelectedPharmacy={setSelectedPharmacy} />}
      </div>
    </>
  )
}
