import { Offtime, parseUTC } from '@aposphaere/core-kit'
import { OfftimeKind } from '@aposphaere/ui-components'
import { isFuture } from 'date-fns'

export const useOfftimesSummary = (offtimes?: Offtime[]) => {
  let homeOfficeCount = 0
  let sickCount = 0
  let meetingsCount = 0
  let vacationCount = 0
  let vacantCount = 0
  let privateCount = 0
  let offtimesUntilTodayCount = 0
  let futureOfftimesCount = 0
  offtimes?.forEach((offtime) => {
    if (!offtime.whole_day) {
      return
    }
    if (!isFuture(parseUTC(offtime.date!))) {
      offtimesUntilTodayCount++
    } else {
      futureOfftimesCount++
    }

    switch (offtime.offtime_type) {
      case OfftimeKind.HomeOffice:
        homeOfficeCount++
        break
      case OfftimeKind.Krank:
        sickCount++
        break
      case OfftimeKind.Tagung:
        meetingsCount++
        break
      case OfftimeKind.Urlaub:
        vacationCount++
        break
      case OfftimeKind.Unbesetzt:
        vacantCount++
        break
      case OfftimeKind.Privat:
        privateCount++
        break
    }
  })

  return {
    offtimesUntilTodayCount,
    futureOfftimesCount,
    offtimeCountsPerType: { homeOfficeCount, sickCount, meetingsCount, vacationCount, vacantCount, privateCount },
  }
}
