import React from 'react'
import { getOpeningHours } from '../../utils/openingHours'

export const OpeningHoursOverview = ({ openingTimes }: { openingTimes?: string }) => {
  const openingHours = getOpeningHours(openingTimes)
  return (
    <div>
      {openingHours.map((text, i) => (
        <div className="flex mb-1" key={i}>
          {text.length === 1 ? (
            <span>{text[0]}</span>
          ) : (
            <>
              <span className="w-1/12">{text[0]}</span>
              <span className="flex-1">{text[1]}</span>
            </>
          )}
        </div>
      ))}
    </div>
  )
}
