import React, { PropsWithChildren, useMemo } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import MapMarkers from './Markers/MapMarkers'
import { determineIsVisit, determineMapIcon } from './utils'
import { Pharmacy } from '@aposphaere/core-kit'
import { useRoutePlanner } from '../../contexts/routePlannerContext'
import { useAppointmentsQuery, useAppointmentTypesQuery, useStatusesQuery } from '../../hooks/graphql'
import { useMap } from '../../contexts/mapContext'

const Map = ({ children, pharmacies, onClick }: PropsWithChildren<{ pharmacies: Pharmacy[] | undefined; onClick?: (id: number) => void }>) => {
  const { selectedDay, sortedAppointmentsToday, sortedVisitsToday } = useRoutePlanner()
  const { fitToMarkers, mapInstance } = useMap()

  const appointmentTypesQuery = useAppointmentTypesQuery()
  const appointmentTypes = appointmentTypesQuery.data?.appointmentTypes

  const { data: appointments } = useAppointmentsQuery()
  const { data: statuses } = useStatusesQuery()
  const statusesForAppointments = statuses?.forAppointments

  const pharmacyAddresses = useMemo(() => (pharmacies?.map ? pharmacies.map(({ id, address }) => ({ id, address })) : []), [pharmacies])

  const markers = useMemo(
    () =>
      pharmacies?.map((pharmacy) => {
        const appointmentsToUse = appointments?.filter((appointment) => appointment.pharmacy?.id === pharmacy.id) ?? []

        const isVisit = determineIsVisit(appointmentsToUse ?? null, {
          appointmentTypes: appointmentTypes ?? [],
          statuses: statusesForAppointments ?? [],
        })
        return {
          id: pharmacy.id,
          lat: pharmacy.address?.latitude,
          long: pharmacy.address?.longitude,
          icon: determineMapIcon({
            pharmacy,
            sortedAppointmentsToday: appointmentsToUse,
            sortedVisitsToday,
            selectedDay,
            isVisit,
          }),
          text: pharmacy.name,
          onClick,
        }
      }) ?? [],

    [pharmacies, sortedAppointmentsToday, appointments, sortedVisitsToday, appointmentTypes, statusesForAppointments, selectedDay, onClick],
  )

  useDeepCompareEffect(() => {
    if (mapInstance) {
      fitToMarkers(pharmacyAddresses)
    }
  }, [pharmacyAddresses, mapInstance])
  return (
    <React.Fragment>
      <MapMarkers markers={markers} />
      {children}
    </React.Fragment>
  )
}

export default Map
