import { Note, parseUTC } from '@aposphaere/core-kit'
import { format } from 'date-fns'
import React, { useMemo } from 'react'

export const NotesOverview = ({ notes }: { notes: Note[] }) => {
  const notesData = useMemo(
    () =>
      notes.map(({ created_at, content }) => ({
        date: format(parseUTC(created_at), 'dd.MM.yyyy HH:mm'),
        content,
      })),
    [notes],
  )
  return (
    <>
      {!notesData.length && <div>Keine Einträge vorhanden</div>}
      {notesData.map(({ date, content }, idx) => (
        <div key={idx}>{`${date} - ${content}`}</div>
      ))}
    </>
  )
}
