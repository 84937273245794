import { createOfftime, deleteOfftime, Offtime, useGqlClient, userOfftimes } from '@aposphaere/core-kit'
import { OffTimeMutationVariables } from '@aposphaere/ui-components'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useCrmContext } from '../../contexts/crmContext'
import { handleOptimisticCreate, handleOptimisticDelete, QueryOptions } from './utils'
import { WHOLE_GERMANY_ID } from '../../pages/DashboardPage/constants'

const CACHE_ID = 'offtimes'

type OfftimesQueryType = {
  options?: QueryOptions<Offtime[]>
  clusterId?: string | null
  date?: { from: string | Date | undefined; to: string | Date | undefined }
  enabled?: boolean
}

const getCacheKey = (clusterId: number | string, date?: OfftimesQueryType['date']) => [
  'offtimes',
  `cluster-${String(clusterId)}`,
  `date-${JSON.stringify(date)}`,
]

/*
 * QUERIES
 */
export const useOfftimesQuery = (props?: OfftimesQueryType) => {
  const gqlClient = useGqlClient()

  const {
    activeCluster: { id: activeClusterId },
  } = useCrmContext()

  const pharmacyClusterId = props?.clusterId ? +props?.clusterId : activeClusterId

  const getSelectedPharmacyCLusterId = (props?: OfftimesQueryType) => {
    if (props?.clusterId === WHOLE_GERMANY_ID) {
      return null
    }
    return Number(pharmacyClusterId)
  }

  const selectedPharmacyCLusterId = getSelectedPharmacyCLusterId(props)

  return useQuery(
    getCacheKey(pharmacyClusterId, props?.date),
    async () => {
      const result = await gqlClient.request<{ offtimes: Offtime[] }>(userOfftimes, {
        pharmacyClusterId: selectedPharmacyCLusterId,
        date: props?.date,
      })
      return result?.offtimes
    },
    { enabled: props?.enabled ?? true, ...props?.options },
  )
}

/*
 * MUTATIONS
 */
interface DeleteOfftimeMutationVariables {
  id: string
}

export const useDeleteOfftimeMutation = (options?: QueryOptions<unknown>) => {
  const gqlClient = useGqlClient()
  const queryClient = useQueryClient()
  return useMutation(async (variables: DeleteOfftimeMutationVariables) => await gqlClient.request<unknown>(deleteOfftime, variables), {
    onMutate: handleOptimisticDelete({ cacheId: CACHE_ID, matchByKeys: ['id'], queryClient }),
    onSettled: () => queryClient.invalidateQueries(CACHE_ID),
    ...options,
  })
}

export const useCreateOfftimeMutation = (options?: QueryOptions<unknown>) => {
  const gqlClient = useGqlClient()
  const queryClient = useQueryClient()
  return useMutation(async (variables: OffTimeMutationVariables) => await gqlClient.request<unknown>(createOfftime, variables), {
    onMutate: handleOptimisticCreate({ cacheId: CACHE_ID, queryClient }),
    onSettled: () => queryClient.invalidateQueries(CACHE_ID),
    ...options,
  })
}
