import {
  Button,
  ButtonKind,
  InputLabel,
  InputMessage,
  InputMessageKind,
  Modal,
  ModalKind,
  TextAreaInput,
  TextInput,
  toast,
} from '@aposphaere/ui-components'
import { createReminder, deleteReminder, updateReminder, useAuth, formattedDateString, Response, convertToUTCForBackend } from '@aposphaere/core-kit'

import { Formik } from 'formik'
import React, { useMemo } from 'react'
import * as Yup from 'yup'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import { useAuthenticatedMutation } from '../../hooks/useAuthenticatedMutation'
import { GENERIC_ERROR_MESSAGE } from '../../constants'
import { usePharmacyQuery, useTasksQuery } from '../../hooks/graphql'
import { useModal } from '../../contexts/modalContext'

type ReminderFormValues = {
  pharmacy_id?: number
  user_id?: number
  state?: string
  contact_person?: string
  until?: Date | string
  note?: string
}

type Props =
  | {
      mode: 'delete' | 'edit'
      id: number
    }
  | {
      mode: 'create'
      pharmacyId: number
      date?: Date
      text?: string
    }

const ReminderModal: React.FC<Props> = (props) => {
  const auth = useAuth()

  const pharmacyId = 'pharmacyId' in props ? props.pharmacyId : undefined
  const reminderId = 'id' in props ? props.id : undefined

  const { closeModal } = useModal()
  const { data: tasks } = useTasksQuery()

  const reminder = useMemo(() => {
    if (!reminderId || !tasks?.length) {
      return
    }

    return tasks.find((task) => task.id === reminderId) ?? null
  }, [tasks, reminderId])

  const { data: pharmacy, refetch: refetchPharmacy } = usePharmacyQuery(pharmacyId || Number(reminder?.pharmacy?.id || 0) || undefined)

  const deleteNoteMutation = useAuthenticatedMutation(deleteReminder)
  const updateNoteMutation = useAuthenticatedMutation(updateReminder)
  const createNoteMutation = useAuthenticatedMutation(createReminder)

  const getMutation = (values: ReminderFormValues) => {
    const defaultVariables = {
      pharmacy_id: values.pharmacy_id,
      user_id: auth.user?.id,
      state: values.state,
      contact_person: values.contact_person,
      until: values.until,
      note: values.note,
    }
    let mutation: (() => Promise<Response<unknown>>) | null = null
    if (props.mode === 'create' && pharmacy) {
      mutation = () => createNoteMutation(defaultVariables)
    }

    if (props.mode === 'delete' && reminderId) {
      mutation = () => deleteNoteMutation({ id: reminderId })
    }

    if (props.mode === 'edit' && reminderId) {
      mutation = () =>
        updateNoteMutation({
          ...defaultVariables,
          id: reminderId,
        })
    }

    return mutation
  }

  const getInitialDate = () => {
    if (props.mode === 'create') {
      if (props.date) {
        return props.date
      }
    }
    return reminder?.until?.toString().replace(/\s/, 'T')
  }

  const initialDate = getInitialDate()

  const getInitialNote = () => {
    if (props.mode === 'create') {
      if (props.text) {
        return props.text
      }
    }
    return reminder?.note || ''
  }

  const initialNote = getInitialNote()

  const intialFormValues: ReminderFormValues = {
    /* initialformvalue */
    pharmacy_id: reminder?.pharmacy.id ?? pharmacy?.id,
    user_id: auth ? auth.user?.id : undefined,
    state: reminder?.state || 'aktiv',
    contact_person: reminder?.contact_person || '',
    until: initialDate,
    note: initialNote,
  }

  /* German localisation  */
  const MONTHS = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
  const WEEKDAYS_LONG = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']
  const WEEKDAYS_SHORT = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']

  const ReminderValidationScheme = Yup.object().shape({
    note: Yup.string().required('Pflichtfeld'),
    contact_person: Yup.string().required('Pflichtfeld'),
    until: Yup.date().required('Pflichtfeld'),
  })

  const tasksQuery = useTasksQuery()
  const onFormSubmit = async (values: ReminderFormValues) => {
    const adjustedValues = {
      ...values,
      until: convertToUTCForBackend(values.until) ?? undefined,
    }

    const mutation = getMutation(adjustedValues)
    if (!mutation) {
      return
    }
    const reminderResponse = await mutation()
    await refetchPharmacy()
    void tasksQuery.refetch()
    if (reminderResponse?.errors !== undefined) {
      alert(reminderResponse.errors)
      return
    }
    try {
      const { notificationText } = getTextObject()
      toast.show({
        headline: notificationText,
        type: 'success',
      })
    } catch {
      toast.show({
        headline: GENERIC_ERROR_MESSAGE,
        type: 'error',
      })
    }

    closeModal()
  }

  const getTextObject = () => {
    if (props.mode === 'delete') {
      return {
        titleModalText: 'Wiedervorlage löschen',
        submitButtonText: 'Löschen bestätigen',
        notificationText: 'Wiedervorlage erfolgreich gelöscht',
      }
    } else if (props.mode === 'edit') {
      return {
        titleModalText: 'Wiedervorlage bearbeiten',
        submitButtonText: 'Änderungen speichern',
        notificationText: 'Wiedervorlage erfolgreich bearbeitet.',
      }
    }
    return {
      titleModalText: 'Wiedervorlage anlegen',
      submitButtonText: 'Wiedervorlage anlegen',
      notificationText: 'Wiedervorlage erfolgreich erstellt',
    }
  }

  const { id = 0, name = 'NAME', okid = 'okid' } = reminder?.pharmacy || pharmacy || {}

  const isLoadingData = (props.mode === 'delete' || props.mode === 'edit') && !reminder

  return (
    <Modal kind={ModalKind.sm} title={getTextObject().titleModalText} onClose={closeModal} onBack={() => null}>
      {isLoadingData ? (
        <div className="min-h-150px">
          <h1>Laden...</h1>
        </div>
      ) : (
        <Formik initialValues={intialFormValues} onSubmit={onFormSubmit} validationSchema={ReminderValidationScheme}>
          {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
            <div className="flex flex-wrap w-full">
              <div className="flex flex-wrap w-full">
                <div className="w-full p-4">
                  <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-6">
                      <InputLabel>{'Standort:'}</InputLabel>
                      <div className="mt-1 text-base font-body">{`${name} (ID: ${id} / ${okid})`}</div>
                    </div>
                    <div className="sm:col-span-3">
                      <InputLabel>{'Status:'}</InputLabel>
                      <div className="flex w-full mt-1 rounded-md">
                        <select
                          disabled={props.mode === 'delete'}
                          value={values.state}
                          className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400"
                          onChange={(event: React.FormEvent<HTMLSelectElement>) => setFieldValue('state', event.currentTarget.value)}
                        >
                          <option value="aktiv">{'aktiv'}</option>
                          <option value="inaktiv">{'erledigt'}</option>
                        </select>
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <InputLabel>{'Datum für Wiedervorlage:'}</InputLabel>
                      <div className="flex w-full mt-1 rounded-md">
                        <DayPickerInput
                          formatDate={formattedDateString}
                          placeholder={'DD.MM.YYYY'}
                          dayPickerProps={{
                            disabledDays: { before: new Date() },
                            firstDayOfWeek: 1,
                            months: MONTHS,
                            weekdaysLong: WEEKDAYS_LONG,
                            weekdaysShort: WEEKDAYS_SHORT,
                            locale: 'de',
                          }}
                          inputProps={{
                            readOnly: true,
                            className:
                              'form-input font-body block w-full h-10 bg-gray-100 rounded-md px-4 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5',
                          }}
                          value={values.until ? formattedDateString(new Date(values.until)) : ''}
                          onDayChange={(day) => setFieldValue('until', day)}
                        />
                      </div>
                      {errors.until && touched.until ? <InputMessage kind={InputMessageKind.error}>{errors.until}</InputMessage> : null}
                    </div>
                    <div className="sm:col-span-6">
                      <InputLabel>{'Gesprächspartner * in:'}</InputLabel>
                      <div className="mt-1 rounded-md">
                        <TextInput
                          value={values.contact_person || ''}
                          disabled={props.mode === 'delete'}
                          onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('contact_person', event.currentTarget.value)}
                          type="text"
                        />
                      </div>
                      {errors.contact_person && touched.contact_person ? (
                        <InputMessage kind={InputMessageKind.error}>{errors.contact_person}</InputMessage>
                      ) : null}
                    </div>
                    <div className="sm:col-span-6">
                      <InputLabel>{'Notiz:'}</InputLabel>
                      <div className="mt-1 rounded-md">
                        <TextAreaInput
                          disabled={props.mode === 'delete'}
                          initialValue={props.mode === 'create' ? (!!props.text ? props.text : values.note) : values.note}
                          onChange={(event: React.FormEvent<HTMLTextAreaElement>) => setFieldValue('note', event.currentTarget.value)}
                        />
                      </div>
                      {errors.note && touched.note ? <InputMessage kind={InputMessageKind.error}>{errors.note}</InputMessage> : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-between p-4 pt-8 pb-6 place-items-stretch">
                <Button kind={ButtonKind.secondary} onClick={closeModal}>
                  {'Abbrechen'}
                </Button>
                <Button kind={props.mode === 'delete' ? ButtonKind.danger : ButtonKind.primary} onClick={handleSubmit} disabled={isSubmitting}>
                  {getTextObject().submitButtonText}
                </Button>
              </div>
            </div>
          )}
        </Formik>
      )}
    </Modal>
  )
}

export default ReminderModal
