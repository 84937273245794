import React from 'react'
import {
  DeactivateIcon,
  EditIcon,
  LocationProfileIcon,
  LockedForCallsIcon,
  PlusSignIcon,
  ProjectstatusIcon,
  ReminderIcon,
} from '@aposphaere/ui-components'

const QuickActionsLegend: React.FC = () => {
  const rowStyle = 'flex items-center'
  const textStyle = 'p-2'
  return (
    <div className="text-base bg-white mr-3">
      <div className={`${rowStyle}`}>
        <LocationProfileIcon />
        <span className={`${textStyle}`}>Kundenstammblatt</span>
      </div>
      <div className={`${rowStyle}`}>
        <ProjectstatusIcon />
        <span className={`${textStyle}`}>Projekte bearbeiten</span>
      </div>
      <div className={`${rowStyle}`}>
        <PlusSignIcon />
        <span className={`${textStyle}`}>Termin anlegen</span>
      </div>
      <div className={`${rowStyle}`}>
        <ReminderIcon />
        <span className={`${textStyle}`}>Wiedervorlage anlegen</span>
      </div>
      <div className={`${rowStyle}`}>
        <LockedForCallsIcon />
        <span className={`${textStyle}`}>Callcentersperre</span>
      </div>
      <div className={`${rowStyle}`}>
        <EditIcon />
        <span className={`${textStyle}`}>Notiz anlegen</span>
      </div>
      <div className={`${rowStyle}`}>
        <DeactivateIcon />
        <span className={`${textStyle}`}>Apothekenstatus</span>
      </div>
    </div>
  )
}

export default QuickActionsLegend
