import React, { useMemo } from 'react'
import { DashboardStructureType, useDashboardContext } from '../../../contexts/dashboardContext'
import Spinner from '../../../components/loader.gif'
import { InfoContainer } from '../InfoContainer'
import { OfftimeColorCode } from '@aposphaere/ui-components'
import AppointmentGraph from '../AppointmentGraph'
import OverviewPerProject from '../OverviewPerProject'

export const DashboardContent = () => {
  const {
    isLoading,
    dataForDownload,
    [DashboardStructureType.SUMMARY_CARDS]: {
      appointmentsCompleted,
      appointmentsBooked,
      visitsCompleted,
      visitsBooked,
      averageAppointmentCount,
      bookedPharmaciesCount,
      pharmaciesCount,
      trainedPharmaciesCount,
      bookedForFuturePharmaciesCount,
      pharmaciesWithMultipleAppointmentsCount,
      pharmaciesWithCancelledAppointmentsCount,
      trainingsQuote,
      pastTrainingSets,
      averageTrainingsPerDay,
      repeatedTrainigsCount,
      totalSlots,
      bookedSlots,
      completedSlots,
      averageSlotsPerAppointment,
      averageSlotsPerDay,
      totalParticipantCount,
      averageParticipantsPerAppointment,
      homeOfficeCount,
      vacationCount,
      privateCount,
      meetingsCount,
      sickCount,
      vacantCount,
      totalWorkingDays,
      totalDaysInField,
      appointmentsCreatedByAgent,
      exitQuestionResponseRate,
    },
    [DashboardStructureType.GRAPH]: dataForGraph,
    [DashboardStructureType.SUMMARY_PER_PROJECT]: summaryPerProjectData,
  } = useDashboardContext()

  const summaryCardsData = useMemo(
    () => [
      {
        title: 'Crm Termine',
        summaryValue: appointmentsCompleted + appointmentsBooked + visitsCompleted + visitsBooked,
        denominatorValue: appointmentsCreatedByAgent,
        data: [
          { label: 'Termine bis heute', value: appointmentsCompleted },
          { label: 'Offene Termine', value: appointmentsBooked },
          { label: 'Besuche bis heute', value: visitsCompleted },
          { label: 'Offene Besuche', value: visitsBooked },
          { label: 'Ø pro Tag', value: averageAppointmentCount },
        ],

        onDownloadClick: () => {},
      },
      {
        title: 'Terminierte Apotheken',
        summaryValue: bookedPharmaciesCount,
        denominatorValue: pharmaciesCount,
        data: [
          { label: 'geschult', value: trainedPharmaciesCount },
          { label: 'terminiert', value: bookedForFuturePharmaciesCount },
          {
            label: 'davon Mehrfachterminierung',
            value: pharmaciesWithMultipleAppointmentsCount,
          },
          { label: 'abgesagt', value: pharmaciesWithCancelledAppointmentsCount },
          { label: 'Schulungsquote', value: trainingsQuote.toFixed(1) + '%' },
          { label: 'Abschlussantwortquote', value: exitQuestionResponseRate.toFixed(1) + '%' },
        ],

        onDownloadClick: () => {},
      },
      {
        title: 'Schulungsdurchgänge',
        summaryValue: pastTrainingSets,
        data: [
          {
            label: 'davon Mehrfachdurchgänge',
            value: repeatedTrainigsCount,
          },
          { label: 'Ø pro Tag', value: averageTrainingsPerDay },
        ],

        onDownloadClick: () => {},
      },
      {
        title: 'Slots',
        summaryValue: totalSlots,
        data: [
          {
            label: 'avisiert',
            value: bookedSlots,
          },
          { label: 'durchgeführt', value: completedSlots },
          {
            label: 'Ø pro Termin',
            value: averageSlotsPerAppointment,
          },
          { label: 'ist Ø am Tag', value: averageSlotsPerDay },
        ],

        onDownloadClick: () => {},
      },
      {
        title: 'Teilnehmer',
        summaryValue: totalParticipantCount,
        data: [
          {
            label: 'Ø pro Termin',
            value: averageParticipantsPerAppointment,
          },
        ],
      },
    ],
    [
      appointmentsCompleted,
      appointmentsCreatedByAgent,
      appointmentsBooked,
      visitsCompleted,
      visitsBooked,
      averageAppointmentCount,
      bookedPharmaciesCount,
      pharmaciesCount,
      trainedPharmaciesCount,
      bookedForFuturePharmaciesCount,
      pharmaciesWithMultipleAppointmentsCount,
      pharmaciesWithCancelledAppointmentsCount,
      exitQuestionResponseRate,
      trainingsQuote,
      pastTrainingSets,
      averageTrainingsPerDay,
      repeatedTrainigsCount,
      totalSlots,
      bookedSlots,
      completedSlots,
      averageSlotsPerAppointment,
      averageSlotsPerDay,
      totalParticipantCount,
      averageParticipantsPerAppointment,
    ],
  )

  const offtimesData = useMemo(
    () => [
      { style: 'border text-black-100', label: 'Feldtage gesamt', value: totalWorkingDays },
      { style: 'border text-black-100', label: 'Verfügbare Feldtage', value: totalDaysInField },
      { style: OfftimeColorCode.HomeOffice, label: 'Home Office', value: homeOfficeCount },
      { style: OfftimeColorCode.Urlaub, label: 'Urlaub', value: vacationCount },
      { style: OfftimeColorCode.Privat, label: 'Privat', value: privateCount },
      { style: OfftimeColorCode.Tagung, label: 'Tagung', value: meetingsCount },
      { style: OfftimeColorCode.Krank, label: 'Krank', value: sickCount },
      { style: OfftimeColorCode.Unbesetzt, label: 'Unbesetzt', value: vacantCount },
    ],
    [homeOfficeCount, vacationCount, privateCount, meetingsCount, sickCount, vacantCount, totalDaysInField, totalWorkingDays],
  )

  if (isLoading) {
    return (
      <div className="flex flex-col w-full h-full items-center justify-center">
        <img className="flex w-24" src={Spinner} alt="" />
        <br />
        <div className="text-center font-body text-base text-blue-400">{'Daten werden geladen.'}</div>
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-wrap">
        {summaryCardsData.map((card) => (
          <InfoContainer key={card.title} {...card} dataForDownload={dataForDownload} />
        ))}
        <div className="flex flex-wrap border-b p-3 content-start gap-2" style={{ flex: '1 1 33%' }}>
          {offtimesData.map(
            ({ style, label, value }) =>
              value > 0 && (
                <div key={label} className={`h-fit p-2 font-body text-base rounded-md overflow-visible text-white ${style} shadow-sm capitalize`}>
                  {value} {label}
                </div>
              ),
          )}
        </div>
      </div>
      {dataForGraph ? <AppointmentGraph dataForGraph={dataForGraph} /> : null}
      <OverviewPerProject summaryPerProjectData={summaryPerProjectData} />
    </>
  )
}
