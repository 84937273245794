import React, { createContext, useContext, useCallback, useEffect, useMemo, useState, ReactNode, Dispatch, SetStateAction } from 'react'
import { Project, Quarter, formattedTimeString, parseUTC } from '@aposphaere/core-kit'

import { useAppointmentsQuery, useAppointmentTypesQuery, useOfftimesQuery, useQuartersQuery } from '../../hooks/graphql'
import { isFuture, isWithinInterval, isPast, addHours, isSameDay, isSameHour, isSameMinute, isBefore, isAfter } from 'date-fns'
import { CalendarTimeSlot } from '../CrmCalendar/useCalendarData'
import { ModalKind, useModal } from '../../contexts/modalContext'
import { AppointmentOrderDetailsItem } from './models'
import { timeSchema } from '../../utils/validationSchemas'
import { useCrmContext } from '../../contexts/crmContext'

export enum StepStates {
  DateSelection = 'DateSelection',
  DetailsSection = 'DetailsSection',
}

const TIME_HAS_A_HOLIDAY_MESSAGE = 'Der ausgewählte Tag ist ein Feiertag'

interface ICalendarPanelContext {
  activeState: StepStates
  selectedTime: Date
  setSelectedTime: Dispatch<SetStateAction<Date>>
  selectedOrderItems: OrderDetailsItem[]
  setSelectedOrderItems: Dispatch<SetStateAction<OrderDetailsItem[]>>
  selectedQuarter: Quarter | undefined
  isOccupied: boolean
  endDate: Date
  updateOrderItems: (amount: string, projectid: string, project: Project) => void
  validateAndSetTime: (value: string, schema: boolean, checkCurrent?: boolean) => string | void
  onTimeslotClick: (slot: CalendarTimeSlot) => void
  selectedMode: string
  setSelectedMode: Dispatch<SetStateAction<string>>
  goTo: (state: StepStates) => void
}

const CalendarPanelContext = createContext<ICalendarPanelContext | null>(null)

export type OrderDetailsItem = {
  project_id: string
  amount: string
  length_in_seconds: number
}

const CalendarPanelWrapper = ({ children }: { children: ReactNode }) => {
  const { data: appointments } = useAppointmentsQuery()
  const { data: offtimes } = useOfftimesQuery()
  const { data: quarters } = useQuartersQuery()
  const { activeCluster } = useCrmContext()
  const agentStateHolidays = useMemo(() => activeCluster?.agent?.address?.federal_state?.holidays || [], [
    activeCluster?.agent?.address?.federal_state?.holidays,
  ])
  const [orderItemsChanged, setOrderItemsChanged] = useState(false)
  const [selectedTime, setSelectedTime] = useState<Date>(new Date())
  const [selectedOrderItems, setSelectedOrderItems] = useState<AppointmentOrderDetailsItem[]>([])
  const [selectedQuarter, setSelectedQuarter] = useState<Quarter | undefined>(undefined)

  const [isOccupied, setIsOccupied] = useState<boolean>(false)

  const validQuarters = useMemo<Quarter[]>(() => {
    if (quarters) {
      return quarters.filter((quarter) => quarter?.to && isFuture(parseUTC(quarter.to)))
    }
    return []
  }, [quarters])

  const [activeState, setActiveState] = useState<StepStates>(StepStates.DateSelection)
  const [selectedMode, setSelectedMode] = React.useState<string>('slot')

  const goTo = (state: StepStates) => {
    setActiveState(state)
  }

  const appointmentTypesQuery = useAppointmentTypesQuery()
  const appointmentTypes = appointmentTypesQuery.data?.appointmentTypes
  const unavailableTimes: string[] = useMemo(() => {
    if (!selectedQuarter?.to || !selectedQuarter?.from) {
      return []
    }
    const VISIT_APPOINTMENT_TYPE_FRAGMENT = 'besuch'
    const visitAppointmentType = appointmentTypes?.find((type) => type.label?.toLowerCase()?.includes(VISIT_APPOINTMENT_TYPE_FRAGMENT))

    const filteredAppointments =
      appointments?.filter((appointment) => {
        const appointTypeId = appointment.appointmentType?.id
        return (
          appointment.date &&
          appointTypeId !== visitAppointmentType?.id &&
          selectedQuarter?.from &&
          selectedQuarter?.to &&
          isWithinInterval(parseUTC(appointment.date), { start: parseUTC(selectedQuarter.from), end: addHours(parseUTC(selectedQuarter.to), 23) })
        )
      }) || []
    return filteredAppointments.map((appointment) => appointment.date || '')
  }, [selectedQuarter, appointments, appointmentTypes])

  const updateOrderItems = useCallback(
    (amount: string, projectid: string, project: Project) => {
      const updatedOrderItems: AppointmentOrderDetailsItem[] = Object.assign([], selectedOrderItems)
      // check if item is already in list
      const itemExists = selectedOrderItems.filter((item) => item.project_id === projectid)

      if (itemExists.length > 0) {
        updatedOrderItems.forEach((item, index, object) => {
          if (item.project_id === projectid) {
            Number(amount) === 0 ? object.splice(index, 1) : (item.amount = amount)
          }
        })
        setSelectedOrderItems(updatedOrderItems)
        return
      }
      const orderItem: AppointmentOrderDetailsItem = {
        project_id: projectid,
        amount,
        length_in_seconds: project.booked_time_in_seconds,
      }
      updatedOrderItems.push(orderItem)
      setSelectedOrderItems(updatedOrderItems)
    },
    [selectedOrderItems],
  )

  useEffect(() => {
    setOrderItemsChanged(true)
  }, [selectedOrderItems])

  useEffect(() => {
    if (orderItemsChanged) {
      setOrderItemsChanged(false)
    }
  }, [orderItemsChanged, selectedOrderItems])

  const validateAndSetTime = useCallback(
    (value: string, schema: boolean, checkCurrent?: boolean): string | void => {
      setIsOccupied(false)
      if (!schema) {
        return `Verwenden Sie das Format HH:MM`
      }

      if (!value) {
        return
      }

      const date = new Date(selectedTime || new Date())
      if (!checkCurrent) {
        const [hours, minutes] = value.split(':')
        date.setHours(parseInt(hours, 10))
        date.setMinutes(parseInt(minutes, 10))
      }

      // Check state holidays
      const hasHolidayOnGivenDay = !!agentStateHolidays.find((holiday) => holiday?.date && isSameDay(new Date(holiday.date), date))
      if (hasHolidayOnGivenDay) {
        setIsOccupied(true)
        return TIME_HAS_A_HOLIDAY_MESSAGE
      }

      // // check available time with another appointments
      // if (unavailableTimes.filter((time) => isSameMinute(parseUTC(time), date)).length) {
      //   setIsOccupied(true)

      //   return `Diese Zeit ist bereits belegt`
      // }
      // check available time with offtimes
      // if (
      //   offtimes?.filter((offtime) => {
      //     if (offtime.whole_day) {
      //       return offtime?.date && isSameDay(date, parseUTC(offtime.date))
      //     }
      //     return offtime?.date && isSameHour(date, parseUTC(offtime.date))
      //   }).length
      // ) {
      //   return 'Unmöglich zu diesem Zeitpunkt'
      // }
      // check the currently time is gone
      if (isPast(date)) {
        return 'Diese Zeit ist bereits gekommen'
      }
      // set value if everything clear
      if (!checkCurrent) {
        // Necessary evil in the condition of setting the initial value of the HOK input
        if (selectedTime && !isSameMinute(date, selectedTime)) {
          setSelectedTime(date)
        }
      }
      return undefined
    },
    [offtimes, selectedTime, setIsOccupied, unavailableTimes, agentStateHolidays],
  )

  const { openModal } = useModal()
  const onTimeslotClick = useCallback(
    (slot: CalendarTimeSlot) => {
      if (slot.type === 'free') {
        setSelectedTime(slot.date)
      }
      if (slot.type === 'offtime') {
        openModal({ kind: ModalKind.OfftimesDelete, offtimes: [slot.data] })
      }
    },
    [openModal],
  )

  const endDate = useMemo(
    () => new Date(Math.max(...validQuarters.map((quarter) => new Date(quarter.to?.toString().replace(/\s/, 'T') || '').getTime()))),
    [validQuarters],
  )

  useEffect(() => {
    if (!selectedTime) {
      return
    }
    const quarterOfSelectedTime = validQuarters.find((quarter) => {
      if (!quarter.to || !quarter.from) {
        return false
      }
      return (
        isAfter(selectedTime, parseUTC(quarter.from)) &&
        (isBefore(selectedTime, parseUTC(quarter.to)) || isSameDay(selectedTime, parseUTC(quarter.to)))
      )
    })

    const time = formattedTimeString(selectedTime)
    setSelectedQuarter(quarterOfSelectedTime || undefined)
    validateAndSetTime(time, timeSchema.isValidSync(time))
  }, [selectedTime, validQuarters, validateAndSetTime])

  return (
    <CalendarPanelContext.Provider
      value={{
        activeState,
        selectedTime,
        setSelectedTime,
        selectedOrderItems,
        setSelectedOrderItems,
        selectedQuarter,
        isOccupied,
        onTimeslotClick,
        endDate,
        validateAndSetTime,
        updateOrderItems,
        selectedMode,
        setSelectedMode,
        goTo,
      }}
    >
      {children}
    </CalendarPanelContext.Provider>
  )
}

export default CalendarPanelWrapper

export const useCalendarPanel = () => {
  const calendarContext = useContext(CalendarPanelContext)
  if (!calendarContext) {
    throw new Error('CalendarPanelWrapper is needed to use the hook')
  }
  return calendarContext
}
