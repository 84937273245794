import { ChangeEvent, useCallback } from 'react'
import { useAuthenticatedMutation } from '../../../hooks/useAuthenticatedMutation'
import { setPharmacyProjectStatus } from '@aposphaere/core-kit'
import { toast } from '@aposphaere/ui-components'
import { usePharmacyQuery } from '../../../hooks/graphql'
import { EMPTY_STATUS_ID } from '../../../constants'

interface ISelectedStatus {
  project_id: number
  status_id: string
}

export default function useProjectStatus(pharmacyId: number) {
  const { data: currentlyActivePharmacy, refetch: refetchPharmacy } = usePharmacyQuery(pharmacyId)

  const mutation = useAuthenticatedMutation(setPharmacyProjectStatus)

  const handleChangeProjectStatus = useCallback(
    async ({ project_id, status_id }: ISelectedStatus) => {
      const statusId = status_id === EMPTY_STATUS_ID ? null : Number(status_id)
      const variables = {
        projectId: Number(project_id),
        pharmacyId,
        statusId,
      }

      try {
        await mutation(variables)
        toast.show({
          headline: 'Das Projekt wurde erfolgreich aktualisiert',
          type: 'success',
        })
        void refetchPharmacy()
      } catch {
        toast.show({
          headline: 'Projektstatus konnte nicht aktualisiert werden',
          type: 'error',
        })
      }
    },
    [mutation, pharmacyId, refetchPharmacy],
  )

  const validActivePayload = currentlyActivePharmacy?.projects.map((project) => ({
    project,
    selectedStatusId: project.pivot?.status_id ?? EMPTY_STATUS_ID,
    statusChangeHandler: (event: ChangeEvent<HTMLSelectElement>) => {
      if (event.currentTarget && event.currentTarget.value) {
        void handleChangeProjectStatus({ project_id: project.id, status_id: event.currentTarget.value })
      }
    },
  }))

  return {
    validActivePayload,
  }
}
